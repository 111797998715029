<template>
  <v-container class="login-container">
    <!-- Mostrar formulario para introducir usuario y contraseña -->
    <v-row justify="center" v-show="showLoginForm">
      <v-col cols="12" sm="8" md="4">
        <v-card class="login-form" elevation="12">
          <v-card-title class="text-h5">Identificarse</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field v-model="username" label="Usuario" outlined required></v-text-field>

              <v-text-field v-model="password" label="Contraseña" outlined type="password" required></v-text-field>

              <v-btn type="submit" color="primary">Iniciar sesión</v-btn>
              <router-link :to="routerLink" class="forgot-password-link">
                {{ routerTitle }}
              </router-link>
            </v-form>
            <v-alert v-if="error" type="error" dense>{{ error }}</v-alert>
            <v-alert v-if="success" type="success" dense>{{ success }}</v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Mostrar formulario para introducir el token 2FA para usuarios que lo requieren -->
    <v-row justify="center" v-show="show2FA">
      <v-col cols="12" sm="8" md="4">
        <v-card class="login-form" elevation="12">
          <v-text-field v-model="token" label="Introduzca código de autentificación" outlined required></v-text-field>
          <v-btn @click="verify2FA" color="primary">Verificar</v-btn>
        </v-card>
      </v-col>
    </v-row>

    <!-- Mostrar el spinner -->
    <v-progress-circular v-if="showSpinner" class="spinner" indeterminate color="primary"></v-progress-circular>

    <!-- Snackbar para errores -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="6000">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: '',
      success: '',
      routerLink: '/forgot-password',
      routerTitle: '¿Contraseña olvidada?',
      showLoginForm: true,
      show2FA: false,
      qrCode: '',
      tempSecret: '',
      token: '',
      snackbar: {
        show: false,
        color: '',
        message: ''
      },
      showSpinner: false,
    };
  },
  methods: {
    async login() {
      try {
        this.showSpinner = true;
        const response = await this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/login`, {
          username: this.username,
          password: this.password,
        });
        if (response.data.isComplainer) {
          this.success = 'Autentificación exitosa';
          this.$store.dispatch('setUserAndPersist', response.data.user);
          this.$router.push('/denunciante');
          this.showSpinner = false;
        } else if (response.status === 206) { // Comprobar si se requiere 2FA
          this.show2FA = true;
          this.showLoginForm = false;
        } else {
          // Cuando no se requiere 2FA
          this.success = 'Login successful';
          this.$store.dispatch('setUserAndPersist', response.data.user);
          this.$router.push('/home');
        }
      } catch (error) {
        this.snackbar.color = 'error';
        this.snackbar.message = 'Error en la autentificación';
        this.snackbar.show = true;
        this.error = error.response ? error.response.data.message : 'Login failed';
      } finally {
        this.showSpinner = false;
      }
    },
    async verify2FA() {
      try {
        this.showSpinner = true;
        const response = await this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/verify-2fa`, {
          token: this.token,
        });

        if (response.status === 200) {
          // Update Vuex user state here (assuming the backend sends the user data upon successful verification)
          this.showSpinner = false;
          this.$store.dispatch('setUserAndPersist', response.data.user);
          this.success = '2FA verification successful';
          this.$router.push('/home');
        } else {
          this.showSpinner = false;
          this.error = 'Invalid 2FA Token';
        }
      } catch (error) {
        this.error = error.response ? error.response.data.message : '2FA verification failed';
        this.snackbar.color = 'error';
        this.snackbar.message = this.error;
        this.snackbar.show = true;
      } finally {
        this.showSpinner = false;
      }
    }
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
}

v-card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

v-btn {
  margin-top: 10px;
}

.forgot-password-link {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #1976D2;
  /* Adjust the color as needed */
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>