<template>
  <v-card class="mb-5" elevation="2" min-height="100vh">
    <!-- Formulario de nueva denuncia -->
    <v-card-title class="text-h5"> Por favor, proporcione la siguiente información: </v-card-title>

    <v-card-text>
      <v-form ref="createComplaintForm" v-model="valid" @submit.prevent="createComplaint">
        <v-stepper editable
          :items="['Localización', 'Datos denunciante', 'Tipo de la denuncia', 'Acción denunciada', 'Testigos', 'Valor y documentación', 'Confirmación']">
          <template v-slot:item.1>
            <v-select v-if="!company" label="Entidad/Organización *" :items="companies" item-text="company_name"
              item-value="companyId" v-model="newComplaint.companyId" :rules="[rules.requiredCompany]">
            </v-select>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                Lugar donde se produce el incidente: (<i>Indique dirección física, sucursal, nº de establecimiento</i>...)
              </span>
            </v-sheet>
            <v-text-field label="Calle *" v-model="newComplaint.street" :rules="[rules.required]"></v-text-field>
            <v-text-field label="Código Postal *" v-model="newComplaint.postalCode"
              :rules="[rules.required, rules.postalCodeFormat]"></v-text-field>
            <v-text-field label="Ciudad *" v-model="newComplaint.city" :rules="[rules.required]"></v-text-field>
            <v-text-field label="País *" v-model="newComplaint.country" :rules="[rules.required]"></v-text-field>
          </template>
          <template v-slot:item.2>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                ¿Es usted empleado de la empresa denunciada?
              </span>
            </v-sheet>
            <v-radio-group v-model="newComplaint.companyWorker" row :rules="[rules.required]">
              <v-radio label="Sí" value="1" color="primary"></v-radio>
              <v-radio label="No" value="0" color="primary"></v-radio>
            </v-radio-group>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                ¿Desea permanecer anónimo en la presente denuncia?
              </span>
            </v-sheet>
            <v-radio-group v-model="newComplaint.complainantAnonimous" row>
              <v-radio label="Sí" :value="true" color="primary"></v-radio>
              <v-radio label="No" :value="false" color="primary"></v-radio>
            </v-radio-group>
            <v-sheet v-if="!newComplaint.complainantAnonimous" class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                Si desea que nuestro equipo mediador conozca su identidad, complete los siguientes
                datos:
              </span>
            </v-sheet>
            <v-text-field label="Nombre y apellidos denunciante" v-show="!newComplaint.complainantAnonimous"
              v-model="newComplaint.complainantFullName" required></v-text-field>
            <v-text-field label="Número de Teléfono denunciante" v-show="!newComplaint.complainantAnonimous"
              v-model="newComplaint.complainantPhoneNumber"></v-text-field>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-bold text-red">
                Su dirección de correo solo podrá ser vista por nuestro equipo mediador.
              </p>
            </v-sheet>
            <v-text-field label="Correo electrónico denunciante *" v-model="newComplaint.complainantEmail"
              :rules="[rules.required, rules.emailFormat]"></v-text-field>
          </template>

          <template v-slot:item.3>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                A continuación, se presentan las opciones disponibles para que usted pueda presentar la denuncia.
                Seleccione el tipo de denuncia que desea realizar *.
              </span>
            </v-sheet>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                Infracciones en el ámbito de: * <i>(escoja al menos una opción)</i>
              </span>
            </v-sheet>
            <v-checkbox density="compact" v-for="infraction in infractions" :key="infraction.infractionId"
              :label="infraction.text" :value="infraction.infractionId" v-model="selectedInfractions"
              :rules="[rules.requiredInfraction]" class="custom-checkbox-spacing"></v-checkbox>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Le rogamos que identifique a la(s) persona(s) involucrada(s) en el
                incumplimiento normativo:
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                <i>(Dejar vacio si no se puede identificar a nadie en el incumplimiento normativo).</i>
              </p>
            </v-sheet>
            <v-row>
              <!-- Para el primer nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.defendantFullName" label="Nombre y apellidos"
                  placeholder="Introduce nombre y apellidos" class="custom-v-text-field-spacing">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.defendantJob" label="Cargo/puesto"
                  placeholder="Introduce cargo/puesto" class="custom-v-text-field-spacing">
                </v-text-field>
              </v-col>

              <!-- Para el segundo nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.defendantFullName2" label="Nombre y apellidos"
                  placeholder="Introduce nombre y apellidos" class="custom-v-text-field-spacing">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.defendantJob2" label="Cargo/puesto"
                  placeholder="Introduce cargo/puesto" class="custom-v-text-field-spacing">
                </v-text-field>
              </v-col>

              <!-- Para el tercer nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.defendantFullName3" label="Nombre y apellidos"
                  placeholder="Introduce nombre y apellidos" class="custom-v-text-field-spacing">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.defendantJob3" label="Cargo/puesto"
                  placeholder="Introduce cargo/puesto" class="custom-v-text-field-spacing">
                </v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.4>
            <v-card-title class="text-h5 formTitles">
              Acción denunciada
            </v-card-title>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Le rogamos que describa de manera general la naturaleza de los hechos o el incumplimiento normativo que se
                ha producido: *
              </p>
            </v-sheet>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                <i>(Le rogamos que nos proporcione todos los detalles relativos a la presunta violación o incumplimiento
                  normativo y cualquier otra información que pueda ser valiosa para la evaluación y resolución definitiva
                  de
                  esta situación.</i>
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                <i>Tómese su tiempo y proporcione tantos detalles como sea posible, pero </i><span
                  class="text-red"><i>tenga
                    cuidado de no proporcionar detalles que puedan revelar su identidad a menos que desee
                    hacerlo)</i></span>
              </p>
            </v-sheet>
            <v-textarea v-model="newComplaint.complainantDescription" label="Descripción de los hechos denunciados *"
              placeholder="Descripción" :rules="[rules.required]">
            </v-textarea>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Le rogamos que nos indique el lugar donde se ha producido el incumplimiento normativo y el departamento o
                área afectada:
              </p>
            </v-sheet>
            <v-text-field v-model="newComplaint.complainantPlace" label="Lugar del incumplimiento normativo *"
              placeholder="Lugar" :rules="[rules.required]">
            </v-text-field>
            <v-text-field v-model="newComplaint.complainantDepartment" label="Departamento o área afectada *"
              placeholder="Departamento o área" :rules="[rules.required]" required>
            </v-text-field>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Le rogamos que nos indique una fecha o periodo temporal durante el que se ha producido el incumplimiento
                normativo:
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                <i>(Ejemplos: lunes 3 de junio de 2023, hace 3 semanas, hace 2 meses; a las 15:35 h., entre las 8:00 y las
                  12:00, etc…). Y, en su
                  caso, desde cuándo se está produciendo el incumplimiento normativo.</i>
              </p>
            </v-sheet>
            <v-text-field v-model="newComplaint.complaintDate"
              label="Fecha del incumplimiento normativo *"></v-text-field>
            <v-text-field v-model="newComplaint.complaintTime" label="Hora en la que se produjeron los hechos *"
              placeholder="Hora" :rules="[rules.required]"></v-text-field>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Le rogamos que nos indique cómo se ha dado cuenta del incumplimiento normativo: *
              </p>
            </v-sheet>
            <v-select v-model="newComplaint.modeOfDiscovery" :item-props="true" :items="options"
              label="Modo en el que descubrió los hechos *" outlined :rules="[rules.requiredOption]"></v-select>
          </template>

          <template v-slot:item.5>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Le rogamos que nos indique el nombre y apellidos de los testigos del incumplimiento normativo:
                <i> (Dejar vacio si no existen testigos). </i>
              </p>
            </v-sheet>
            <!-- Para los testigos -->
            <v-card-title class="text-h5 formTitles"> Testigos </v-card-title>
            <!-- Para el primer nombre y cargo -->
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.witnessFullName" label="Nombre y apellidos"
                  class="custom-v-text-field-spacing" placeholder="Introduce nombre y apellidos">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.witnessJob" label="Cargo/puesto" class="custom-v-text-field-spacing"
                  placeholder="Introduce cargo/puesto">
                </v-text-field>
              </v-col>
              <!-- Para el segundo nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.witnessFullName2" label="Nombre y apellidos"
                  class="custom-v-text-field-spacing" placeholder="Introduce nombre y apellidos">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.witnessJob2" label="Cargo/puesto" class="custom-v-text-field-spacing"
                  placeholder="Introduce cargo/puesto">
                </v-text-field>
              </v-col>
              <!-- Para el tercer nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.witnessFullName3" label="Nombre y apellidos"
                  class="custom-v-text-field-spacing" placeholder="Introduce nombre y apellidos">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="newComplaint.witnessJob3" label="Cargo/puesto" class="custom-v-text-field-spacing"
                  placeholder="Introduce cargo/puesto">
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.6>
            <!-- Campo de selección para el valor de la denuncia -->
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                ¿En cuánto estima el valor monetario que el incumplimiento normativo puede generar a la
                Entidad/Organización? *
              </p>
            </v-sheet>
            <v-select label="Seleccione un valor monetario" :rules="[rules.requiredValue]" :items="valuecomplaintOptions"
              v-model="newComplaint.valuecomplaint">
            </v-select>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Puede adjuntar documentos o archivos que prueben la denuncia presentada:
              </p>
              <p class="text-subtitle-1 font-weight-medium"> (<i>Por ejemplo: fotografías</i>.)</p>
            </v-sheet>
            <v-file-input label="Adjuntar archivos" v-model="newComplaint.files"
              accept="image/png, image/jpeg, application/pdf, video/x-matroska" multiple name="files">
            </v-file-input>
          </template>
          <template v-slot:item.7>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-bold">
                DECLARACIÓN DE BUENA FE Y ACEPTACIÓN DE LOS TÉRMINOS
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                Manifiesto que la presente comunicación la realizo de buena fe y que, salvo error u omisión involuntaria,
                los datos consignados son ciertos, así como que:
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                - Conozco del tratamiento que podrá darse a los datos consignados en la presente comunicación
                y
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                - El contenido del procedimiento del canal de denuncias.
              </p>
            </v-sheet>
            <v-checkbox
              label="Acepto los términos, condiciones para la presentación, tramitación y resolución de la presente denuncia y la política de privacidad *"
              v-model="newComplaint.termsAccepted" :rules="[rules.requiredCheckbox]"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="createComplaint">Crear</v-btn>
          </template>
        </v-stepper>
      </v-form>
    </v-card-text>
    <template v-if="isPopup">
      <v-card-actions>
        <v-btn @click="$emit('close-dialog')">Close</v-btn>
      </v-card-actions>
    </template>
  </v-card>
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
    {{ snackbar.message }}
    <v-btn color="white" text @click="snackbar.show = false"> Cerrar </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      default: () => ({ Reseller: {} })
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCreateForm: false,
      newComplaint: {
        street: "",
        postalCode: "",
        city: "",
        country: "",
        companyWorker: false,
        complainantAnonimous: false,
        department: "",
        complainantFullName: "",
        complainantPhoneNumber: "",
        complainantEmail: "",
        valuecomplaint: "",
        companyId: this.company ? this.company.companyId : null,
        defendantFullName: "",
        defendantFullName2: "",
        defendantFullName3: "",
        defendantJob: "",
        defendantJob2: "",
        defendantJob3: "",
        activeInfractions: [],
        complainantDescription: '',
        complainantPlace: '',
        complainantDepartment: '',
        complaintDate: null,
        complaintTime: null,
        reportedComplainant: 1,
        modeOfDiscovery: "",
        witnessFullName: '',
        witnessFullName2: '',
        witnessFullName3: '',
        witnessJob: '',
        witnessJob2: '',
        witnessJob3: '',
        termsAccepted: false,
        status: 'Proceso no iniciado',
        active: true,
        files: [],
        // Algunos campos hacen fallar la petición si se inicializan aquí
        // selectedInfractions: null,
        //resellerId: null,
      },
      rules: {
        required: (value) => !!value || "Requerido",
        requiredCompany: (value) => !!value || "Debe seleccionar una entidad o empresa",
        requiredCheckbox: (value) => value || "Debe aceptar los términos",
        requiredValue: (value) => !!value || "Debe seleccionar un valor estimado",
        requiredInfraction: value => (value && value.length > 0) || 'Debe escoger al menos una opción',
        requiredOption: (value) => !!value || "Debe seleccionar una opción",
        postalCodeFormat: (value) => /^\d{5}$/.test(value) || "Introduzca un código postal válido (5 dígitos)",
        emailFormat: (value) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value) || "Introduzca una dirección de correo electrónico válida";
        },
      },
      valid: true,
      resellerInfo: this.$store.state.resellerInfo,
      companies: [],
      infractions: [],
      selectedInfractions: [],
      newInfraction: {
        selectedInfraction: null, // Aquí se almacenará el valor seleccionado
      },
      valuecomplaintOptions: [
        "De 1€ a 1.000 €",
        "De 1.001 € a 10.000 €",
        "De 10.001 € a 12.000 €",
        "De 12.001 € a 1 Millón €",
        "+ de 1 Millón de €",
        "No sabe cuantificarlo",
      ],
      options: [
        'Me ha sucedido a mí',
        'Fui testigo directo (lo escuché, lo observé)',
        'Me lo dijo un compañero de la entidad',
        'Me lo dijo una persona externa a la entidad',
        'He encontrado un archivo o documento accidentalmente',
        'Otro',
      ],
      snackbar: {
        show: false,
        color: "success",
        message: "",
      },
      baseURL: process.env.VUE_APP_UPLOAD_BASE_URL,

    };
  },
  computed: {
    infractionOptions() {
      return this.infractions.map((infraction) => ({
        ...infraction,
        title: infraction.text, // Esto asume que 'text' es el campo que contiene el nombre de la infracción
      }));
    }
  },
  async mounted() {
    await this.fetchPddInfractions();
  },

  methods: {
    /**
     * Métodos CRUD
     */

    // Crear una denuncia
    async createComplaint() {
      const isValid = await this.$refs.createComplaintForm.validate();
      if (isValid.valid) {
        try {
          const formData = new FormData();
          Object.entries(this.newComplaint).forEach(([key, value]) => {
            if (key !== "files") {
              const trimmedValue = typeof value === 'string' ? value.trim() : value;
              formData.append(key, trimmedValue);
            }
          });
          const resellerId = this.company // && this.company.Reseller
            ? this.company.Reseller.resellerId
            : this.resellerInfo.resellerId;
          formData.append("resellerId", resellerId);
          formData.append("selectedInfractions", this.selectedInfractions);

          this.newComplaint.files.forEach((file) => {
            formData.append("files", file);
          });

          const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints`;
          const response = await this.$axios.post(apiUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          this.showCreateForm = false;
          this.resetNewComplaint();
          this.snackbar.message = "Denuncia creada correctamente";
          this.snackbar.color = "success";
          this.snackbar.show = true;
          this.$router.push('/denuncia-creada');

        } catch (error) {
          this.snackbar.message = "Error al crear la denuncia";
          this.snackbar.color = "error";
          this.snackbar.show = true;
          console.error(error);
        }
      } else {
        this.snackbar.message = "Por favor, complete todos los campos requeridos";
        this.snackbar.color = "error";
        this.snackbar.show = true;
      }
    },

    // Recuperar los tipos de infracción
    async fetchPddInfractions() {
      try {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/pdd-infractions`;
        const response = await this.$axios.get(apiUrl);
        this.infractions = response.data.map((infraction) => ({
          ...infraction,
          title: infraction.text,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * Funciones auxiliares
     */

    // Dar formato a las fechas
    formatDate() {
      return this.newComplaint.complaintDate.toLocaleDateString('es', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },

    // Reiniciar la variable newComplaint
    resetNewComplaint() {
      this.newComplaint = {
        street: "",
        postalCode: "",
        city: "",
        country: "",
        companyWorker: false,
        complainantAnonimous: false,
        department: "",
        complainantFullName: "",
        complainantPhoneNumber: "",
        complainantEmail: "",
        valuecomplaint: "",
        companyId: this.company ? this.company.companyId : null,
        defendantFullName: "",
        defendantFullName2: "",
        defendantFullName3: "",
        defendantJob: "",
        defendantJob2: "",
        defendantJob3: "",
        activeInfractions: [],
        complainantDescription: '',
        complainantPlace: '',
        complainantDepartment: '',
        complaintDate: null,
        complaintTime: null,
        reportedComplainant: 1,
        modeOfDiscovery: "",
        witnessFullName: '',
        witnessFullName2: '',
        witnessFullName3: '',
        witnessJob: '',
        witnessJob2: '',
        witnessJob3: '',
        termsAccepted: false,
        status: 'Proceso no iniciado',
        active: true,
        files: [],
      };
    },
  },
};
</script>

<style scoped>
.v-card-actions {
  justify-content: flex-end;
  /* Alinea los botones a la derecha */
}

/* Esta regla no tiene en cuenta los mensajes de error, puede solapar si se muestran*/
.custom-v-text-field-spacing {
  margin-bottom: -30px;
}

/* ::v-deep .my-checkbox-section .v-input__details .v-messages__message {
  padding-top: -20px !important;
} */

/* ::v-deep .v-input__details .v-icon {
  transform: scale(0.8);
} */

/* ::v-deep .v-input__details .v-label {
  font-size: 0.500rem;
} */
</style>