<template>
    <v-container fluid>
        <!-- Botón para abrir el formulario de nuevo usuario -->
        <v-btn color="secondary" variant="tonal" class="mb-3" @click="showCreateUserDialog = true">
            <v-icon class="mr-4">mdi-account</v-icon>
            Crear Nuevo Usuario
        </v-btn>

        <!-- Cuadros de búsqueda -->
        <v-card class="mb-6" elevation="12">
            <v-btn color="primary" variant="tonal" @click="showSearchFields = !showSearchFields">
                <v-icon class="mr-4" left>mdi-magnify</v-icon>
                Buscar usuario</v-btn>
            <v-card-text v-if="showSearchFields">
                <v-row justify="left">
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchId" label="Buscar usuario por Id" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchUsername" label="Buscar por nombre de usuario" density="compact"
                            variant="solo" single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchEmail" label="Buscar por email" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchPhone" label="Buscar por teléfono" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchDni" label="Buscar por DNI" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchNie" label="Buscar por NIE" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                        <v-btn class="mt-4" @click="resetSearchFields">Limpiar Búsqueda</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <!-- Tabla para listar los usuarios -->
            <v-card>
                <v-card-title>Usuarios</v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="filteredUsers" class="elevation-1">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.userId }}</td>
                                <td>{{ item.username }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ translateRoleName(item.PddRole.role_name) }}</td>
                                <v-btn small variant="tonal" color="secondary" @click="viewUser(item)"
                                    class="ml-4 mt-4">Ver</v-btn>
                                <v-btn small variant="tonal" color="primary" @click="editUser(item)"
                                    class="ml-4 mt-4">Editar</v-btn>
                                <v-btn small variant="tonal" color="error" @click="confirmDeleteUser(item)"
                                    class="ml-4 mt-4">Borrar</v-btn>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card>

        <!-- Tabla para listar los usuarios reseller (manager), sólo visible para usuarios root -->
        <v-card v-if="this.$store.state.user.pddRoleId === 7">
            <v-card-title>Usuarios con permisos de reseller en toda la aplicación</v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="rootormanagers" class="elevation-1">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.userId }}</td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ translateRoleName(item.PddRole.role_name) }}</td>
                            <v-btn small variant="tonal" color="secondary" @click="viewUser(item)"
                                class="ml-4 mt-4">Ver</v-btn>
                            <v-btn small variant="tonal" color="primary" @click="editUser(item)"
                                class="ml-4 mt-4">Editar</v-btn>
                            <v-btn small variant="tonal" color="error" @click="confirmDeleteUser(item)"
                                class="ml-4 mt-4">Borrar</v-btn>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- Diálogo para crear nuevo usuario -->
        <v-dialog v-model="showCreateUserDialog" max-width="500px">
            <v-card>
                <v-card-title>Crear Nuevo Usuario</v-card-title>
                <v-card-text>
                    <v-form ref="userForm">
                        <v-text-field label="Nombre de Usuario *" v-model="newUser.username"
                            :rules="usernameRules"></v-text-field>
                        <v-text-field label="Correo Electrónico *" v-model="newUser.email"
                            :rules="emailRules"></v-text-field>
                        <v-text-field v-model="newUser.password" label="Contraseña *" :rules="passwordRules"></v-text-field>
                        <v-alert color="error" v-if="passwordMessage">
                            <v-layout wrap>
                                <div class="error-message">{{ passwordMessage }}</div>
                            </v-layout>
                        </v-alert>
                        <v-text-field v-model="newUser.fullName" label="Nombre completo del usuario"></v-text-field>
                        <v-select v-if="this.$store.state.user.pddRoleId === 7" label="Tipo de Usuario *"
                            :items="['Root', 'Gerente', 'RSII', 'DPO']" v-model="newUser.pddRoleId"
                            :rules="[v => !!v || 'El tipo de usuario es requerido']"></v-select>
                        <v-select v-if="this.$store.state.user.pddRoleId === 1" label="Tipo de Usuario *"
                            :items="['Gerente', 'RSII', 'DPO',]" v-model="newUser.pddRoleId"
                            :rules="[v => !!v || 'El tipo de usuario es requerido']"></v-select>
                        <v-select v-if="showResellerField" :items="resellers" label="Seleccione un Reseller"
                            v-model="selectedReseller" item-title="name" item-value="resellerId"></v-select>
                        <v-text-field label="DNI" v-model="newUser.dni"></v-text-field>
                        <v-text-field label="NIE" v-model="newUser.nie"></v-text-field>
                        <v-text-field label="Departamento" v-model="newUser.department"></v-text-field>
                        <v-text-field label="Cargo" v-model="newUser.position"></v-text-field>
                        <v-text-field label="Teléfono" v-model="newUser.phone"></v-text-field>
                        <!-- Agregar más campos si es necesario -->
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showCreateUserDialog = false">Cancelar</v-btn>
                    <v-btn color="blue darken-1" text @click="createUser" :disabled="!isFormValid">Crear</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Diálogo para ver detalles de usuario -->
        <v-dialog v-model="showViewUserDialog" max-width="500px">
            <v-card>
                <v-card-title>Detalles de Usuario</v-card-title>
                <v-card-text>
                    <p><strong>Nombre de usuario:</strong> {{ currentUser.username }}</p>
                    <p><strong>Nombre completo:</strong> {{ currentUser.fullName || "No facilitado" }}</p>
                    <p><strong>Correo:</strong> {{ currentUser.email || "No facilitado" }}</p>
                    <p><strong>Rol:</strong> {{ translateRoleName(currentUser.PddRole.role_name) }}</p>
                    <p><strong>DNI <i>(usuario no denunciante)</i>:</strong> {{ currentUser.dni || "No facilitado" }}</p>
                    <p><strong>NIE <i>(usuario no denunciante)</i>:</strong>{{ currentUser.nie || "No facilitado" }}</p>
                    <p><strong>Cargo <i>(usuario no denunciante)</i>:</strong>{{ currentUser.position || "No facilitado" }}
                    </p>
                    <p><strong>
                            Departamento <i>(usuario no denunciante)</i>:
                        </strong>
                        {{ currentUser.department || "No facilitado" }}</p>
                    <p><strong>Teléfono:</strong> {{ currentUser.phone || "No facilitado" }}</p>
                    <!-- Agregar más detalles si es necesario -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showViewUserDialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Diálogo para editar usuario -->
        <v-dialog v-model="showEditUserDialog" max-width="500px">
            <v-card>
                <v-card-title>Editar Usuario</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field label="Nombre completo" v-model="currentUser.fullName"></v-text-field>
                        <v-text-field label="Correo Electrónico" v-model="currentUser.email"></v-text-field>
                        <v-text-field v-if="currentUser.PddRole.role_name !== 'complainant'" label="DNI"
                            v-model="currentUser.dni"></v-text-field>
                        <v-text-field v-if="currentUser.PddRole.role_name !== 'complainant'" label="NIE"
                            v-model="currentUser.nie"></v-text-field>
                        <v-text-field v-if="currentUser.PddRole.role_name !== 'complainant'" label="Cargo"
                            v-model="currentUser.position"></v-text-field>
                        <v-text-field v-if="currentUser.PddRole.role_name !== 'complainant'" label="Departamento"
                            v-model="currentUser.department"></v-text-field>
                        <v-text-field label="Teléfono" v-model="currentUser.phone"></v-text-field>
                        <v-text v-if="currentUser.pddRoleId === 2 || currentUser.pddRoleId === 3">Compañías
                        </v-text>
                        <v-checkbox v-if="currentUser.pddRoleId === 2 || currentUser.pddRoleId === 3"
                            v-for="company in companies" :key="company.companyId" :label="company.company_name"
                            :value="company.companyId" v-model="selectedCompanyIds"
                            :input-value="currentUser.Companies.some(c => c.companyId === company.companyId)">
                        </v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showEditUserDialog = false">Cancelar</v-btn>
                    <v-btn color="blue darken-1" text @click="updateUser(currentUser)">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Diálogo para confirmar borrado de usuario -->
        <v-dialog v-model="showConfirmDeleteDialog" persistent max-width="300px">
            <v-card>
                <v-card-title class="headline">Confirmación</v-card-title>
                <v-card-text>¿Está seguro de que desea eliminar a este usuario?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="showConfirmDeleteDialog = false">Cancelar</v-btn>
                    <v-btn color="red darken-1" text @click="deleteUser(currentUser)">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            usernameRules: [
                v => !!v || 'El nombre de usuario es requerido',
                v => (v && v.length >= 6) || 'El nombre de usuario debe tener al menos 6 caracteres',
                v => !/\s/.test(v) || 'El nombre de usuario no puede contener espacios'
            ],
            emailRules: [
                v => !!v || 'El correo electrónico es requerido',
                v => /.+@.+\..+/.test(v) || 'El correo electrónico debe tener un formato válido'
            ],
            passwordRules: [
                v => !!v || 'Se requiere establecer una contraseña',
                this.passwordComplexityRule,
            ],

            showSearchFields: false,
            searchId: '',
            searchUsername: '',
            searchEmail: '',
            searchDni: '',
            searchNie: '',
            searchPhone: '',
            getUpdateRole: "",
            showCreateForm: false,
            passwordMessage: "",
            newUser: {
                username: '',
                email: '',
                password: '',
                fullName: '',
                dni: '',
                nie: '',
                position: '',
                department: '',
                phone: '',
                pddRoleId: 'Seleccione un rol',
                companyId: null,
                resellerId: null,
            },
            users: [],
            rootormanagers: [],
            resellers: [],
            companies: [],
            selectedCompanyId: [],
            selectedReseller: null,
            headers: [
                { title: 'ID', value: 'userId' }, // Columna 'ID' añadida
                { title: 'Nombre de Usuario', value: 'username' },
                { title: 'Email', value: 'email' },
                { title: 'Tipo de Usuario', value: 'PddRole.role_name' }, // Asumiendo que 'PddRole.role_name' es una ruta válida en el objeto del ítem
                { title: 'Acciones', value: 'actions', sortable: false },
            ],
            showCreateUserDialog: false,
            showViewUserDialog: false,
            showEditUserDialog: false,
            showConfirmDeleteDialog: false,
            currentUser: {},
            selectedCompanyIds: [],
            snackbar: {
                show: false,
                color: 'success',
                message: ''
            },
        };
    },
    mounted() {
        this.fetchUsers();
        this.fetchCompanies();
        if (this.$store.state.user && this.$store.state.user.pddRoleId === 7) {
            this.fetchResellers();
            this.fetchRootOrManagers();
        }

    },
    computed: {
        userInfo() {
            return this.$store.state.user;
        },
        showResellerField() {
            return this.userInfo && this.userInfo.pddRoleId === 7;
        },
        // Necesario para validar el formulario
        isFormValid() {
            return (
                this.newUser.username &&
                this.newUser.email &&
                this.newUser.password &&
                this.newUser.pddRoleId !== 'Seleccione un rol'
            );
        },
        // Para la funcionalidad del buscador. Suma las cadenas de búsqueda en todos los campos, case insensitive
        filteredUsers() {
            return this.users.filter(user => {
                const matchesId = !this.searchId || (user.userId && user.userId.toString().includes(this.searchId));
                const matchesUsername = !this.searchUsername || (user.username && user.username.toString().toLowerCase().includes(this.searchUsername.toLowerCase()));
                const matchesEmail = !this.searchEmail || (user.email && user.email.toString().toLowerCase().includes(this.searchEmail.toLowerCase()));
                const matchesDni = !this.searchDni || (user.dni && user.dni.toString().toLowerCase().includes(this.searchDni.toLowerCase()));
                const matchesNie = !this.searchNie || (user.nie && user.nie.toString().toLowerCase().includes(this.searchNie.toLowerCase()));
                const matchesPhone = !this.searchPhone || (user.phone && user.phone.toString().toLowerCase().includes(this.searchPhone.toLowerCase()));

                return matchesId && matchesUsername && matchesEmail && matchesDni && matchesNie && matchesPhone;
            });
        },

    },
    methods: {
        /**
         * Métodos auxiliares
         */

        passwordComplexityRule(v) {
            if (!v) return 'Se requiere establecer una contraseña';
            if (v.length < 10) return 'La contraseña debe tener al menos 10 caracteres de largo';
            if (!/[A-Z]/.test(v)) return 'Debe incluir al menos una letra mayúscula';
            if (!/[a-z]/.test(v)) return 'Debe incluir al menos una letra minúscula';
            if (!/[0-9]/.test(v)) return 'Debe incluir al menos un número';
            if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(v)) return 'Debe incluir al menos un carácter no alfanumérico (por ejemplo: $%&)';
            if (/\s/.test(v)) return 'No se permiten espacios ni saltos de línea en la contraseña';
            return true;
        },

        // Reiniciar los campos de búsqueda
        resetSearchFields() {
            this.searchId = '';
            this.searchUsername = '';
            this.searchEmail = '';
            this.searchDni = '';
            this.searchNie = '';
            this.searchPhone = '';
        },
        // Reiniciar el formulario
        resetForm() {
            this.newUser.username = '';
            this.newUser.email = '';
            this.newUser.password = '';
            this.newUser.dni = '';
            this.newUser.nie = '';
            this.newUser.position = '';
            this.newUser.department = '';
            this.newUser.phone = '';
            this.newUser.pddRoleId = 'Seleccione un rol';
            this.newUser.companyId = null;
        },
        // Ver usuario
        viewUser(user) {
            this.currentUser = { ...user };
            this.showViewUserDialog = true;
        },
        // Traducción de la cadena rol de usuario a valor numérico para rellenar la clave foránea
        transformRoleNameToId(roleName) {
            switch (roleName) {
                case 'Root':
                    return 7;
                case 'Gerente':
                    return 1;
                case 'RSII':
                    return 2;
                case 'DPO':
                    return 3;
                case 'Denunciante':
                    return 4;
            }
        },
        translateRoleName(roleName) {
            const roleTranslations = {
                'manager': 'Reseller',
                'lawyer': 'RSII',
                'company_user': 'DPO',
                'complainant': 'Denunciante',
                'root': 'Usuario root',
            };

            return roleTranslations[roleName]; // Return the translation or the original role name if no translation is found
        },
        // Método para abrir el diálogo de edición con la información del usuario actual
        editUser(user) {
            this.currentUser = { ...user };
            if (this.currentUser.pddRoleId === 2 || this.currentUser.pddRoleId === 3) {
                this.selectedCompanyIds = this.currentUser.Companies.map(company => company.companyId);
            }

            this.showEditUserDialog = true;
        },

        /**
         * Métodos CRUD
         */
        // Recuperar usuarios
        async fetchUsers() {
            try {
                const userInfo = this.userInfo;
                if (!userInfo || !userInfo.resellerId) {
                    throw new Error("Información del usuario no disponible");
                }
                const id = userInfo.resellerId;
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/users/reseller/${id}`;
                const response = await this.$axios.get(apiUrl);
                this.users = response.data;
                this.users.selectedCompanyId = [];
                this.selectedCompanyIds = this.users.flatMap(user => {
                    if (user.Companies) {
                        return user.Companies.map(company => company.companyId);
                    } else {
                        return [];
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        async fetchRootOrManagers() {
            try {
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/users/roles/rootormanagers`;
                const response = await this.$axios.get(apiUrl);
                this.rootormanagers = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        // Crear usuario
        async createUser() {
            // Validar campos en primer lugar
            if (this.isFormValid) {
                try {
                    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/registro`;
                    if (this.userInfo && this.userInfo.pddRoleId === 7) {
                        this.newUser.resellerId = this.selectedReseller;
                    } else {
                        this.newUser.resellerId = this.userInfo.resellerId;
                    }
                    this.newUser.pddRoleId = this.transformRoleNameToId(this.newUser.pddRoleId);
                    if (this.selectedCompanyId) {
                        this.newUser.companyId = this.selectedCompanyId.companyId;
                    }
                    const response = await this.$axios.post(apiUrl, this.newUser);
                    this.snackbar.message = 'Usuario creado con éxito';
                    this.snackbar.color = 'success';
                    this.showCreateUserDialog = false;
                    // this.newUser.pddRoleId = 'Seleccione un rol';
                    this.fetchUsers();
                    // Llamar a fetchUsers para actualizar la lista
                } catch (error) {
                    console.error('Error al crear usuario:', error);
                    this.snackbar.color = 'error';
                    this.snackbar.message = 'Error al crear usuario';
                }
                this.snackbar.show = true;
                this.resetForm();
            }
        },
        // Actualizar información de usuario
        async updateUser(user) {
            try {
                user.selectedCompanyIds = this.selectedCompanyIds;
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/users/${user.userId}`;
                const response = await this.$axios.put(apiUrl, user);
                this.snackbar.message = 'Usuario actualizado con éxito';
                this.showEditUserDialog = false;
                // Llamar a fetchUsers para actualizar la lista
                this.fetchUsers();
            } catch (error) {
                console.error('Error al actualizar usuario:', error);
                this.snackbar.message = 'Error al actualizar usuario';
                this.snackbar.color = 'alert';
                this.snackbar.show = true;
            }
            this.snackbar.color = 'success';
            this.snackbar.show = true;
        },

        // Confirmar el borrado de un usuario
        confirmDeleteUser(user) {
            this.currentUser = { ...user };
            this.showConfirmDeleteDialog = true;
        },

        // Borrar un usuario
        async deleteUser(user) {
            try {
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/users/${user.userId}`;
                const response = await this.$axios.delete(apiUrl);
                this.snackbar.message = 'Usuario eliminado con éxito';
                this.showConfirmDeleteDialog = false;
                this.fetchUsers();
                // Llamar a fetchUsers para actualizar la lista
            } catch (error) {
                console.error('Error al borrar usuario:', error);
                this.snackbar.message = 'Error al borrar usuario';
            }
            this.snackbar.color = 'success';
            this.snackbar.show = true;
        },
        // Recuperar resellers
        async fetchResellers() {
            try {
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/resellers`;
                const response = await this.$axios.get(apiUrl);
                this.resellers = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        // Recuperar compañías por reseller de usuario
        async fetchCompanies() {
            try {
                if (!this.userInfo || !this.userInfo.resellerId) {
                    throw new Error("Información del reseller de usuario no disponible");
                }
                const id = this.userInfo.resellerId
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/companies/reseller/${id}`;
                const response = await this.$axios.get(apiUrl);
                this.companies = response.data;
            } catch (error) {
                console.error(error);
            }
        },


    },
};
</script>
<style scoped>

.error-message {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

</style>