import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Para el calendario

axios.defaults.withCredentials = true;

// Estado inicial
const initialState = {
    user: null,
    resellerInfo: null,
    timestamp: 0, // Agrega una marca de tiempo para el control de expiración
};

const vuetify = createVuetify({
    components,
    directives,
    locale: {
        locale: 'es',
      },
});

const startApp = async () => {

    const storedState = localStorage.getItem('vuex_state');


    if (storedState) {

        const parsedState = JSON.parse(storedState);
        const currentTime = new Date().getTime();
        const expirationTime = 30 * 60 * 1000; // 30 minutos en milisegundos


        if (currentTime - parsedState.timestamp < expirationTime) {
            // El estado está dentro del tiempo límite, cárgalo
            store.replaceState(parsedState);
        } else {
            // El estado ha expirado, borra la información almacenada
            localStorage.removeItem('vuex_state');
            // Aquí puedes reemplazar el estado de Vuex con el estado inicial si es necesario
            store.replaceState(initialState);
        }
    } else {
        // Si no hay estado almacenado, utiliza el estado inicial
        store.replaceState(initialState);
    }

    const app = createApp(App);


    app.config.globalProperties.$axios = axios;
    app.use(router);
    app.use(store);
    app.use(vuetify);

    window.addEventListener('beforeunload', () => {
        // Actualiza la marca de tiempo antes de guardar el estado
        store.state.timestamp = new Date().getTime();
        localStorage.setItem('vuex_state', JSON.stringify(store.state));
    });

    // Hacer disponibles los datos del reseller en toda la aplicación
    // app.provide('resellerInfo', resellerInfo);

    app.mount('#app');
};

// Iniciar la aplicación
startApp();
