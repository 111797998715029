import { createStore } from 'vuex';
import axios from 'axios';


const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    localStorage.setItem('vuex_state', JSON.stringify(state));
  });
};

export default createStore({
  state: {
    user: null, // resellerInfo: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    // Acción para establecer el usuario y persistir en localStorage
    setUserAndPersist(context, user) {
      context.commit('setUser', user);
      localStorage.setItem('vuex_state', JSON.stringify(context.state));
    },
    async logout({ commit }) {
      try {
        // Realiza una solicitud al backend para cerrar la sesión
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/logout`);

        // Despacha la mutación para limpiar el estado del usuario
        commit('clearUser');
        // Borrar la información almacenada
        localStorage.removeItem('vuex_state');

        // Opcional: Realiza cualquier limpieza adicional necesaria
      } catch (error) {
        console.error('Error al cerrar sesión:', error);
        // Maneja el error según sea necesario
      }
    },
  },
  plugins: [localStoragePlugin],
});
