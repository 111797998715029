<template>
    <vue-scroll-snap>
        <v-container fluid class="pt-0 mt-0 item"
            style="background: url('/images/columnas_romanas.png') center / cover no-repeat;">
            <v-card class="background-card" color="rgba(255, 255, 255, 0.6)">
                <animated-component>
                    <v-row class="full-viewport" align="center" justify="center">
                        <v-col cols="12" sm="10" md="8" lg="6" class="text-center" mb-0>
                            <v-card class="pa-sm-4 fade-in responsive-card" color="rgba(255, 255, 255, 0.6)"
                                elevation="8" rounded="lg">
                                <div class="text-h2 slide-right mb-4"><v-img src="/images/logo_landing.png"
                                        max-width="45%" class="center-image"></v-img>
                                </div>
                                <v-card color="red">
                                    <h1 class="py-sm-4">¡Protege a tus empleados, protege a tu empresa!</h1>
                                </v-card>
                                <div class="text-subtitle-1 slide-left responsive-text">
                                    <h3 class="mt-sm-4">Portal de denuncias para cumplir con Ley 2/2023, de 20 de
                                        febrero.
                                    </h3>
                                </div>
                            </v-card>
                            <v-row align="start">
                                <v-col cols="12" sm="4">
                                    <v-card class="mt-sm-4 pa-sm-4" color="red" rounded="lg" elevation="8">
                                        <h3>Fácil de usar y acceso desde cualquier lugar</h3>
                                        <p>----</p>
                                        <p>Económico y sin cuotas ocultas</p>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-card class="mt-sm-4 pa-sm-4" color="red" rounded="lg">
                                        <h3>Asesoramiento jurídico profesional</h3>
                                        <p>----</p>
                                        <p>Soporte post-venta</p>
                                    </v-card>
                                    <v-card class="my-sm-2 pa-4" color="black" elevation="8">
                                        <v-btn><a class="custom-link" href="#contactContainer">Más
                                                información</a></v-btn>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="mt-sm-4 pa-sm-4" color="red" rounded="lg" elevation="8">
                                        <h3>Ideado para:</h3>
                                        <p>----</p>
                                        <p>Empresas finales</p>
                                        <p>Asesorías y despachos profesionales</p>
                                        <p>Administración pública</p>

                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </animated-component>
            </v-card>
        </v-container>

        <!-- bloque 1 -->
        <v-container fluid class="bg-grey-darken-4 full-viewport item">
            <animated-component>
                <v-row class="d-flex align-center">
                    <v-col cols="12" md="6" class="order-md-1">
                        <v-img class="slide-right" src="/images/notes.jpg" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" class="order-md-2">
                        <div class="pa-4">
                            <p class="red-text text-h4">FÁCIL DE USAR</p>
                            <p class="text-h6">Fácil de usar y sin complicaciones para tu empresa.</p>
                        </div>
                    </v-col>
                </v-row>
            </animated-component>
        </v-container>
        <!-- bloque 2 -->
        <v-container fluid class="bg-grey-lighten-2 item full-viewport">
            <animated-component>
                <v-row class="d-flex align-center">
                    <v-col cols="12" md="6" class="order-md-2">
                        <v-img class="slide-left" src="/images/secure.jpg" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" class="order-md-1">
                        <div class="pa-4  d-flex flex-column justify-center align-right">
                            <p class="red-text text-h4 text-right">SIEMPRE SEGURO</p>
                            <p class="text-h6 text-right">Garantizamos la seguridad de tus datos.</p>
                        </div>
                    </v-col>
                </v-row>
            </animated-component>
        </v-container>
        <!-- bloque 3 -->
        <v-container fluid class="bg-grey-darken-4 item full-viewport">
            <animated-component>
                <v-row class="d-flex align-center">
                    <v-col cols="12" md="6" class="order-md-1">
                        <v-img class="slide-right" src="/images/enterprise.jpg" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" class="order-md-2">
                        <div class="pa-4">
                            <p class="red-text text-h4">ADAPTADO A TU EMPRESA</p>
                            <p class="text-h6">Dispone de un canal personalizado y adaptado a tus necesidades.</p>
                        </div>
                    </v-col>
                </v-row>
            </animated-component>
        </v-container>
        <!-- bloque 4 -->
        <v-container fluid class="bg-grey-lighten-2 item full-viewport">
            <animated-component>
                <v-row class="d-flex align-center">
                    <v-col cols="12" md="6" class="order-md-2">
                        <v-img class="slide-left" src="/images/legal.jpg" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" class="order-md-1">
                        <div class="pa-4 d-flex flex-column justify-center align-right">
                            <p class=" red-text text-h4 text-right">CUMPLIMIENTO LEGAL</p>
                            <p class="text-h6 text-right">Cumple con la legislación española y protege tu anonimato.</p>
                        </div>
                    </v-col>
                </v-row>
            </animated-component>
        </v-container>

        <!-- bloque 5 -->
        <v-container fluid class="bg-grey-darken-4 item full-viewport">
            <animated-component>
                <v-row class="d-flex align-center">
                    <v-col cols="12" md="6" class="order-md-1">
                        <v-img class="slide-right" src="/images/cloud.jpg" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col cols="12" md="6" class="order-md-2">
                        <p class="red-text text-h4">PLATAFORMA EN LA NUBE</p>
                        <p class="text-h6">Plataforma siempre accesible desde cualquier lugar y dispositivo.</p>
                    </v-col>
                </v-row>
            </animated-component>
        </v-container>
        <!-- bloque 6 -->
        <v-container fluid class="pt-0 mt-0 item full-viewport"
            style="background: url('/images/whitepiramids.jpg') center / cover no-repeat;">
            <animated-component>
                <v-row class="full-viewport" align="center" justify="center">
                    <v-col cols="12" sm="10" md="8" lg="6" class="text-center">
                        <v-card class="pa-4 fade-in responsive-card" color="rgba(255, 255, 255, 0.3)" elevation="2"
                            rounded="lg">
                            <v-card-text class="red-text text-h5">
                                Portal de denuncias para cumplir con Ley 2/2023, de 20 de febrero.
                            </v-card-text>
                            <v-card-text class="text-body-1">
                                Nuestro servicio de canal de denuncias/sistema interno de información, cumple con
                                los <b>requisitos de confidencialidad, anonimato, seguridad y protección al
                                    denunciante/informante</b>, exigidos por la Ley 2/2023, de 20 de febrero.
                            </v-card-text>
                            <v-card-text class="text-body-1">
                                Le garantizamos el cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre, de
                                Protección
                                de Datos Personales y garantía de los derechos digitales y el Reglamento (UE) 2016/679
                                Reglamento General de Protección de Datos.
                            </v-card-text>
                            <v-card-text class="text-body-1">
                                Si precisa implantar un canal denuncias para usted o un cliente solicite un presupuesto.
                            </v-card-text>
                            <v-card-text class="text-body-1 red-text">
                                Desde 35 euros al mes. Solicite un presupuesto sin compromiso.
                            </v-card-text>
                            <v-card-text class="text-body-1">
                                Colaboramos con despachos profesionales para proporcionar este servicio para sus
                                clientes.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </animated-component>
        </v-container>
        <v-container id="contactContainer" fluid class="pt-0 mt-0 item full-viewport bg-grey-darken-4">
            <v-row class="full-viewport mt-6" d-flex justify="center">
                <v-col xs="10" sm="10" md="8" lg="6" class="text-center">
                    <p class="text-white">Rellene y envíenos este formulario y contactaremos con Vd. antes de 24 horas.
                    </p>
                    <v-card class="pa-4 fade-in responsive-card" color="rgba(255, 255, 255, 1.0)" rounded="lg">
                        <v-form @submit.prevent="submitForm">
                            <v-text-field v-model="nombre" label="Nombre*"></v-text-field>
                            <v-text-field v-model="email" label="Email*"></v-text-field>
                            <v-text-field v-model="telefono" label="Teléfono"></v-text-field>
                            <v-textarea v-model="mensaje" label="Mensaje*"></v-textarea>
                            <v-checkbox density="compact" v-model="politicasPrivacidad"
                                label="Acepto expresamente mediante el presente envío de email las políticas de privacidad de la entidad en la web"></v-checkbox>
                            <v-checkbox density="compact" v-model="publicidad"
                                label="Acepto recibir publicidad e información promocional"></v-checkbox>
                            <v-btn :disabled="!politicasPrivacidad || !publicidad" type="submit"
                                color="primary">Enviar</v-btn>
                        </v-form>

                    </v-card>
                    <div class="text-caption text-start">
                        <p>Corresponsable del tratamiento: PROTOCOLOS DE SEGURIDAD INFORMATICA PROTECMIR S.L.,
                            NIF/CIF:
                            B87193207, Dirección: PASEO DE LA CASTELLANA, Nº 40, 8ª PLANTA, CP: 28046, MADRID,
                            Teléfono
                            971918848, Email: protecmirlegal@protecmir.com. DELEGADO DE PROTECCIÓN DE DATOS:
                            CONTACTO:
                            http://www.protecmir.com, EMAIL: protecmirlegal@protecmir.com.</p>
                        <p>Corresponsable del tratamiento: J. AGUILO SISTEMAS Y REDES S.L., CIF: B07934318,
                            Domicilio: CAMINO NOU 7, BAJOS 2, 07009 PALMA DE MALLORCA, BALEARES, Dirección de correo
                            electrónico: dpo@syr.es, Teléfono: 971249957, Inscrito en el Registro Mercantil Islas
                            Baleares al Tomo 1627, Folio 123, Hoja PM 31250, Sección 8.</p>
                        <p>Finalidad: Los datos únicamente serán utilizados para la finalidad que se hayan recogido.
                            Legitimación: Ejecución de un contrato. Destinatarios: No se cederán datos a terceros,
                            salvo obligación legal. Transferencias Internacionales: No se realizan transferencias
                            internacionales. Derechos: Puede ejercer sus derechos de acceso, rectificación,
                            supresión y oposición, limitación del tratamiento, portabilidad de datos y a no ser
                            objeto de decisiones individualizadas, así como retirar el consentimiento prestado,
                            mediante las direcciones, postal y electrónica, indicadas en el apartado Responsable. En
                            el ejercicio de sus derechos debe identificarse, incorporando copia de DNI o de un
                            documento oficial válido que le identifique, y especificar claramente el derecho que
                            ejerce. Del mismo modo puede reclamar ante la Autoridad de Control, para más detalle
                            consulte la web www.aepd.es. Puede consultar la información adicional y detallada sobre
                            Protección de Datos en nuestra página web: <a href="/politica-privacidad"
                                class="text-link">Política de
                                privacidad</a></p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false"> Cerrar </v-btn>
        </v-snackbar>

        <footer-component class="item full-viewport"></footer-component>
    </vue-scroll-snap>
</template>

<script>
import AnimatedComponent from '../components/AnimatedComponent.vue';
import VueScrollSnap from 'vue-scroll-snap';
import FooterComponent from '@/components/FooterComponent.vue';
export default {
    name: "App",
    components: {
        AnimatedComponent,
        VueScrollSnap,
        FooterComponent,
    },
    data() {
        return {
            nombre: '',
            email: '',
            telefono: '',
            mensaje: '',
            snackbar: {
                show: false,
                color: "success",
                message: "",
            },
            politicasPrivacidad: false,
            publicidad: false,
        };
    },
    methods: {
        async submitForm() {
            try {
                const formData = {
                    nombre: this.nombre,
                    email: this.email,
                    telefono: this.telefono,
                    mensaje: this.mensaje
                };
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/contact-us`;
                const response = await this.$axios.post(apiUrl, formData);
                this.snackbar.message = "Mensaje enviado correctamente";
                this.snackbar.color = "success";
                this.snackbar.show = true;
            } catch (error) {
                this.snackbar.message = "Error al enviar el mensaje";
                this.snackbar.color = "error";
                this.snackbar.show = true;
                console.error(error);
            }
        },
    },
    computed: {
        isHomePage() {
            console.log('routename en main view', this.$route.name);
            return this.$route.name === 'mainview';
        },
    },
};
</script>

<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideRight {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.fade-in {
    animation: fadeIn 2s ease-in-out;
}

.slide-right {
    animation: slideRight 2s ease-in-out;
}

.slide-left {
    animation: slideLeft 2s ease-in-out;
}

/* Colour the scroll panels. */
#one {
    background: #1e91d6;
}

#two {
    background: #db5461;
}

#three {
    background: #fc7753;
}

#four {
    background: #ffffff;
}

.item {
    /* Set the minimum height of the items to be the same as the height of the container.*/
    min-height: 50vh;
}

.scroll-snap-container {
    /* Set the container size. */
    /* width: 100vw; */
    height: 100vh;

    /* Center the div in the middle of the screen. */
    /* right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%); */
}

.center-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.multi-line-btn {
    white-space: normal;
    text-align: left;
}

.red-text {
    color: #B71C1C;
}

.v-img {
    max-height: 90vh;
}

.text-link {
    text-decoration: none;
    color: #db5461;
}

.background-card {
    margin-left: -16px !important;
    margin-right: -16px !important;
    margin-bottom: -16px !important;
}

.full-viewport {
    min-height: 100vh;
}
</style>