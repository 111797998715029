<template>
    <v-container>
        <v-row>
            <h2>POLÍTICA DE COOKIES</h2>
            <v-col cols="12">
                <v-card class="mb-4" elevation="2">
                    <h3 class="headline">¿Qué son las cookies?</h3>
                    <v-card-text>
                        Este sitio web (portaldenuncias.online) utiliza cookies y/o tecnologías similares que almacenan
                        y recuperan
                        información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy
                        diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de
                        navegación, o personalizar la forma en que se muestra el contenido. Los usos concretos que
                        hacemos
                        de estas tecnologías se describen a continuación.
                    </v-card-text>
                </v-card>

                <v-card class="mb-4" elevation="2">
                    <h3>Tipos de cookies</h3>
                    <v-card-text>
                        <v-list>
                            <v-list-item-group>
                                <v-list-item>
                                    <v-list-item-content>
                                        <p>Según quién sea la entidad que gestione el dominio desde dónde se
                                            envían las cookies y se traten los datos que se obtengan, se pueden
                                            distinguir
                                            dos tipos: <b>cookies propias</b> y <b>cookies de
                                                terceros</b>.
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <p>Existe también una segunda clasificación según el plazo de
                                            tiempo
                                            que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de
                                            <b>cookies de sesión</b> o <b>cookies persistentes</b>.</p>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <p>También existe otra clasificación, según su finalidad para la
                                            que
                                            se traten los datos obtenidos.</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>



                        <v-subheader>Tipos de cookies según su finalidad:</v-subheader>
                        <v-list dense>
                            <v-list-item-group>
                                <v-list-item>
                                    <strong>Cookies técnicas:</strong> Facilitan la navegación y la
                                    utilización de las diferentes opciones o servicios que ofrece la
                                    web.
                                </v-list-item>

                                <v-list-item>
                                    <strong>Cookies de personalización:</strong> Las cookies de
                                    análisis son las utilizadas para llevar a cabo el análisis anónimo del
                                    comportamiento de los usuarios de la web y que permiten medir la actividad del
                                    usuario y elaborar perfiles de navegación con el fin objetivo de mejorar los
                                    sitios web.
                                </v-list-item>

                                <v-list-item>
                                    <strong>Cookies analíticas:</strong> Permiten al usuario
                                    acceder a los servicios según sus preferencias (idioma, navegador,
                                    configuración…).
                                </v-list-item>

                                <v-list-item>
                                    <strong>Cookies publicitarias:</strong>Las cookies publicitarias
                                    permiten la gestión de los espacios publicitarios de la web. Además, estas
                                    cookies pueden ser de publicidad personalizada y permitir así la gestión de los
                                    espacios publicitarios de la web en base al comportamiento y hábitos de
                                    navegación de usuario, de donde se obtiene su perfil y permiten personalizar la
                                    publicidad que se muestra en el navegador del usuario u otros perfiles y redes
                                    sociales del usuario.
                                </v-list-item>

                                <v-list-item>
                                    <strong>Cookies publicitarias compartamentales:</strong> Las
                                    cookies publicitarias compartamentales son aquellas que, tratadas por nosotros o
                                    por terceros, nos permiten analizar tus hábitos de navegación en Internet para
                                    que podamos mostrarte publicidad relacionada con tu perfil de
                                    navegación.
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>

                <v-card class="mb-4" elevation="2">
                    <h3>Cookies utilizadas en la web</h3>
                    <v-card-text>
                        <p>Nuestra web utiliza exclusivamente cookies técnicas propias.</p>
                        <p>Se almacena una cookie una vez se acepta el banner inicial de aceptación de cookies, cuya
                            finalidad es reconocer dicha opción y no volver a mostrar el banner.</p>
                        <p>Se almacena una cookie de sesión cuando un usuario se autentifica en la aplicación, cuya
                            finalidad es permitir el acceso autentificado del usuario e interactuar debidamente con la
                            aplicación.</p>
                    </v-card-text>

                </v-card>

                <v-card class="mb-4" elevation="2">
                    <h3>Cómo modificar la configuración de las cookies</h3>
                    <v-card-text>
                        Puedes restringir, bloquear o borrar las cookies de nuestra web o cualquier otra página
                        utilizando
                        tu navegador. En cada navegador la operativa es diferente. Aquí te mostramos cómo puedes hacerlo
                        en
                        los navegadores más comunes:
                        <v-list>
                            <v-list-item>
                                <p><strong>Chrome:</strong> Configuración => Privacidad y seguridad =>
                                    Cookies y otros datos de sitios. <a
                                        href="https://support.google.com/chrome/answer/95647?hl=es"
                                        target="_blank">support.google.com</a></p>
                            </v-list-item>

                            <v-list-item>
                                <p><strong>Firefox:</strong> Opciones => Privacidad y seguridad =>
                                    Cookies y
                                    datos de sitios. <a
                                        href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
                                        target="_blank">support.mozilla.org</a></p>
                            </v-list-item>

                            <v-list-item>
                                <p><strong>Edge:</strong> Configuración => Privacidad, búsqueda y
                                    servicios
                                    => Cookies y datos de sitios. <a
                                        href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                                        target="_blank">support.microsoft.com</a></p>
                            </v-list-item>

                            <v-list-item>
                                <p><strong>Safari (Mac):</strong> Preferencias => Privacidad =>
                                    Gestionar
                                    datos de sitios web. <a
                                        href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                                        target="_blank">support.apple.com</a></p>
                            </v-list-item>

                            <v-list-item>
                                <p><strong>Opera:</strong> Configuración => Privacidad y seguridad =>
                                    Cookies. <a href="https://help.opera.com/en/latest/web-preferences/#cookies"
                                        target="_blank">help.opera.com</a></p>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>


                <v-card class="mb-4" elevation="2">
                    <h3>ACEPTACIÓN DE LA POLÍTICA DE COOKIES</h3>
                    <v-card-text>
                        Al acceder a nuestra web, en cumplimiento del artículo 22 de la Ley de Servicios de la Sociedad
                        de
                        la Información, te hemos solicitado tu consentimiento para su uso. El suministro de datos
                        personales
                        a través de nuestra web y el consentimiento para el uso de cookies requiere una edad mínima de
                        14
                        años y la aceptación expresa de nuestra Política de Privacidad.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

    <footer-component class="item"></footer-component>
</template>
<script>
import FooterComponent from '@/components/FooterComponent.vue';
export default {
    name: "App",
    components: {
        FooterComponent,
    },
}
</script>