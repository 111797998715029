<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <v-img src="/images/notfound.jpg" max-width="600" class="mx-auto"></v-img>
            </v-col>
        </v-row>
        <div>
            <h1 class="d-flex justify-center">ERROR EN LA NAVEGACIÓN</h1>
            <h4 class="d-flex justify-center">Si el error persiste, contacte con su proveedor.</h4>
        </div>
    </v-container>
</template>