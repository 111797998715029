<template>
  <v-container fluid class="h-screen">
    <v-app-bar app>
      <!-- Contenido del navbar -->
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-toolbar-title>Identificado como: {{ username }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout" text><b>Cerrar sesión</b></v-btn>
      <!-- Diálogo de confirmación para logout -->
      <v-img :src="qrCodeUrl" alt="QR Code"></v-img>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">Confirmar logout</v-card-title>
          <v-card-text>¿Estás seguro de que quieres cerrar sesión?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="confirmLogout">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="card-title">Mi usuario</v-card-title>
          <v-btn small color="secondary" @click="toggleUserDataVisibility()" class="mx-4 my-4">Ver</v-btn>
          <v-btn small color="primary" @click="editUser()" class="mr-4">Editar</v-btn>
          <v-card-text v-if="userDataVisible">
            <v-list>
              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Nombre de usuario: {{ currentUser.username }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Nombre completo: {{ currentUser.fullName || 'No declarado' }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Email: {{ currentUser.email || 'No declarado' }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-card-account-details</v-icon>
                </v-list-item-icon>
                <v-list-item-content>DNI: {{ currentUser.dni || 'No declarado' }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-card-account-details-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>NIE: {{ currentUser.nie || 'No declarado' }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-briefcase</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Cargo: {{ currentUser.position || 'No declarado' }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Departamento: {{ currentUser.department || 'No declarado' }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="me-4">
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Teléfono: {{ currentUser.phone || 'No declarado' }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Diálogo para editar usuario -->
    <v-dialog v-model="showEditUserDialog" max-width="500px">
      <v-card>
        <v-card-title>Editar Usuario</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field label="Nombre completo" v-model="currentUser.fullName"></v-text-field>
            <v-text-field label="Correo Electrónico" v-model="currentUser.email" required></v-text-field>
            <v-text-field label="DNI" v-model="currentUser.dni"></v-text-field>
            <v-text-field label="NIE" v-model="currentUser.nie"></v-text-field>
            <v-text-field label="Cargo" v-model="currentUser.position"></v-text-field>
            <v-text-field label="Departamento" v-model="currentUser.department"></v-text-field>
            <v-text-field label="Teléfono" v-model="currentUser.phone"></v-text-field>
            <!-- Agregar más campos si es necesario -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showEditUserDialog = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="updateUser(currentUser)">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    const user = this.$store.state.user || {};
    return {
      username: user.username || 'No declarado',
      fullName: user.fullName || 'No declarado',
      email: user.email || 'No declarado',
      dni: user.dni || 'No declarado',
      nie: user.nie || 'No declarado',
      position: user.position || 'No declarado',
      department: user.department || 'No declarado',
      phone: user.phone || 'No declarado',
      qrCodeUrl: user.qrCodeUrl,
      dialog: false,
      currentUser: this.$store.state.user,
      userDataVisible: false,
      showEditUserDialog: false,
      showConfirmDeleteDialog: false,
      getUpdateRole: "",
      snackbar: {
        show: false,
        color: 'success',
        message: ''
      },
    };
  },
  methods: {
    editUser() {
      this.currentUser = this.$store.state.user;
      this.showEditUserDialog = true;
    },
    // Actualizar información de usuario
    async updateUser(user) {
      try {
        user.pddRoleId = this.currentUser.pddRoleId;
        user.userId = this.currentUser.userId;
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/users/${user.userId}`;
        const response = await this.$axios.put(apiUrl, user);
        // this.currentUser = user;
        this.currentUser = { ...this.currentUser, ...response.data };

        this.snackbar.message = 'Usuario actualizado con éxito';
        this.showEditUserDialog = false;
        this.snackbar.color = 'success';
        this.snackbar.show = true;
      } catch (error) {
        console.error('Error al actualizar usuario:', error);
        this.snackbar.message = 'Error actualizando usuario';
        this.snackbar.color = 'alert';
        this.snackbar.show = true;
      }
    },
    // Confirmar salida
    confirmLogout() {
      this.$store.dispatch('logout')
        .then(() => {
          // Redirigir a la página de login después de cerrar sesión
          this.$router.push('/login');
          this.dialog = false;
        })
        .catch(error => {
          // Manejar el error si es necesario
          console.error('Error en logout:', error);
        });
    },
    // Salida
    logout() {
      this.dialog = true;
    },
    // Mostrar u ocultar información del usuario
    toggleUserDataVisibility() {
      this.userDataVisible = !this.userDataVisible;
    },
  },
};
</script>

<style>
.card-style {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  transition: 0.3s;
  background-color: #fff;
}

.card-title {
  background-color: #5c6bc0;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-style:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.card-text {
  padding: 20px;
}
</style>
