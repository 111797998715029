<template>
  <v-container min-height="100vh">

    <!-- Cuadros de búsqueda -->
    <v-card class="mb-6" elevation="12">
      <!-- Botón para mostrar u ocultar los campos de búsqueda -->
      <v-btn color="primary" variant="tonal" @click="showSearchFields = !showSearchFields">
        <v-icon class="mr-4" left>mdi-magnify</v-icon>
        Buscar denuncia</v-btn>
      <v-card-text v-if="showSearchFields">
        <v-row justify="left">
          <v-col cols="12" sm="6" md="4">
            <v-text-field class="mb-2" v-model="searchId" label="Buscar denuncia por Id" density="compact" variant="solo"
              single-line hide-details></v-text-field>
            <v-text-field v-model="searchCompanyName" label="Buscar por nombre de empresa" density="compact"
              variant="solo" single-line hide-details></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field v-model="searchDate" label="Buscar por fecha" density="compact" variant="solo" single-line
              hide-details></v-text-field>
            <v-text-field class="py-2" v-model="searchCity" label="Buscar por ciudad" density="compact" variant="solo"
              single-line hide-details></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field v-if="userInfo.pddRoleId === 2" v-model="searchEmail" label="Buscar por email" density="compact"
              variant="solo" single-line hide-details></v-text-field>
            <v-btn class="my-2" @click="resetSearchFields">Limpiar Búsqueda</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-row justify="center">
        <!-- Denuncias nuevas Card -->
        <v-col cols="16" md="2">
          <v-card hover>
            <v-card-title>Denuncias nuevas</v-card-title>
            <v-card-text>
              <h1>{{ complaintCounts[0] }}</h1>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Bajo revisión Card -->
        <v-col cols="16" md="2">
          <v-card hover>
            <v-card-title>En proceso de revisión</v-card-title>
            <v-card-text>
              <h1>{{ complaintCounts[1] }}</h1>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Bajo revisión Card -->
        <v-col cols="16" md="2">
          <v-card hover>
            <v-card-title>Pendiente de respuesta</v-card-title>
            <v-card-text>
              <h1>{{ complaintCounts[2] }}</h1>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Resueltas Card -->
        <v-col cols="16" md="2">
          <v-card hover>
            <v-card-title>Proceso cerrado</v-card-title>
            <v-card-text>
              <h1>{{ complaintCounts[3] }}</h1>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="mb-4"></v-divider>


      <!-- Tabla para listar las denuncias -->
      <v-card-text>
        <v-card-title class="text-h5"> Listado de Denuncias </v-card-title>
      </v-card-text>

      <v-card v-if="complaints.length > 0" class="mb-6 px-4 py-4" elevation="12">
        <div v-for="(complaint, index) in filteredComplaints" :key="index">
          <v-card class="mx-4 my-4" elevation="8">
            <v-table class="mb-4">
              <thead>
                <tr>
                  <th class="soft_bold_title">Identificador</th>
                  <th class="soft_bold_title">Entidad</th>
                  <th class="soft_bold_title">Fecha de registro de la denuncia</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ complaint.complaintId }}</td>
                  <td>{{ complaint.Company.company_name }}</td>
                  <td>{{ formatDate(complaint.createdAt) }}</td>
                </tr>

                <tr>
                  <v-btn small color="secondary" @click="viewComplaint(complaint)"
                    class="buttonCrudActions"><v-icon>mdi-book-open</v-icon><span class="ml-4">Ver denuncia</span></v-btn>
                </tr>
              </tbody>
            </v-table>

            <!-- Cards para mostrar y editar mensajes y estatus -->
            <v-row>
              <MessagingComponent v-if="userInfo.pddRoleId === 2" :key="complaint.complaintId" :complaint="complaint"
                :user-info="$store.state.user" />
              <v-col cols="12" md="6">
                <div class="mx-2 mb-2">
                  <v-badge :color="getStatusColor(complaint.status)" class="mx-4 mb-2" small></v-badge>
                  <span class="mx-4">Estado: {{ complaint.status }}</span>
                </div>
                <v-select v-if="complaint.status != 'Proceso cerrado' && userInfo.pddRoleId === 2"
                  v-model="selectedStatus" label="Seleccionar estado"
                  :items="['En proceso de revisión', 'Pendiente de respuesta']" class="buttonCrudActions ml-4">
                </v-select>
                <v-btn v-if="complaint.status != 'Proceso cerrado' && userInfo.pddRoleId === 2" small color="primary"
                  variant="tonal" @click="openDialog(complaint.complaintId)" class="mb-4 ml-3">Cambiar el estado
                </v-btn>
                <v-btn v-if="complaint.status != 'Proceso cerrado' && userInfo.pddRoleId === 2" color="success"
                  variant="tonal" @click="openResolutionDialog(complaint.complaintId)" class="mb-4 ml-3">Agregar
                  Resolución</v-btn>
                <v-btn v-if="complaint.status === 'Proceso cerrado'" color="info" variant="tonal" class="mb-4 mt-6 ml-8"
                  @click="fetchResolution(complaint.complaintId)">Ver
                  Resolución</v-btn>

                <!-- Diálogo para cambiar estado -->
                <v-dialog v-model="showDialog" persistent max-width="500px">
                  <v-card>
                    <v-card-title>Confirmación</v-card-title>
                    <v-card-text>¿Cambiar el estado de la denuncia? La persona denunciante recibirá una notificación
                      acerca del nuevo estado.</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="grey" @click="showDialog = false">Cancelar</v-btn>
                      <v-btn color="primary" @click="confirmUpdateState()">Aceptar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Diálogo para guardar Resolution -->
                <v-dialog v-model="showResolutionDialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title class="text-h5">Resolución de la Denuncia</v-card-title>
                    <v-card-text>
                      <v-textarea v-model="resolutionText" label="Texto de Resolución"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="showResolutionDialog = null">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="saveResolution(complaint.complaintId)">Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Diálogo para ver Resolution -->
                <v-dialog v-model="showResolutionContentDialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title class="text-h5">Contenido de la Resolución</v-card-title>
                    <v-card-text style="white-space: pre-wrap;">{{ resolutionText }}</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="showResolutionContentDialog = false">Cerrar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-text class="mx-4">No se han encontrado denuncias.</v-card-text>
      </v-card>
    </v-card>

    <!-- Diálogo para ver detalles de la denuncia -->
    <v-dialog v-model="showViewDialog" persistent max-width="600px">
      <v-card>
        <v-card-title> Detalles de la Denuncia </v-card-title>
        <v-card-text>
          <v-card-text>
            <h3>Lugar donde se produce el incidente</h3>
          </v-card-text>
          <p>
            <span class="soft_bold_title">Calle:</span>
            {{ currentComplaint.street }}
          </p>
          <p>
            <span class="soft_bold_title">Ciudad:</span>
            {{ currentComplaint.city }}
          </p>
          <p>
            <span class="soft_bold_title">Código postal:</span>
            {{ currentComplaint.postalCode }}
          </p>
          <p>
            <span class="soft_bold_title">País:</span>
            {{ currentComplaint.country }}
          </p>
          <v-card-text>
            <h3>Datos de la persona demandante</h3>
          </v-card-text>
          <p v-if="currentComplaint.companyWorker">
            <span class="soft_bold_title">Trabaja en la empresa denunciada</span>
          </p>
          <p v-if="!currentComplaint.companyWorker">
            <span class="soft_bold_title">No trabaja en la empresa denunciada</span>
          </p>
          <p v-if="currentComplaint.complainantAnonimous">
            <span class="soft_bold_title">Es una denuncia anónima</span>
          </p>
          <p v-if="!currentComplaint.complainantAnonimous">
            <span class="soft_bold_title">No es una denuncia anónima</span>
            {{ currentComplaint.department }}
          </p>
          <p v-if="!currentComplaint.complainantAnonimous && userInfo.pddRoleId === 2">
            <span class="soft_bold_title">Nombre del/de la demandante:</span>
            {{ currentComplaint.complainantFullName }}
          </p>
          <p v-if="!currentComplaint.complainantAnonimous && userInfo.pddRoleId === 2">
            <span class="soft_bold_title">Número de teléfono:</span>
            {{ currentComplaint.complainantPhoneNumber }}
          </p>
          <p v-if="userInfo.pddRoleId === 2">
            <span class="soft_bold_title">Dirección de correo:</span>
            {{ currentComplaint.complainantEmail }}
          </p>
          <v-card-text>
            <h3>Tipo de la denuncia</h3>
          </v-card-text>
          <p>
            <span class="soft_bold_title">Denuncias en los ámbitos de:</span>
          </p>
          <p v-for="infraction in checkedInfractions" :key="infraction">{{ infraction }}</p>
          <v-card-text>
            <h3 v-if="currentComplaint.defendantFullName != '' ||
              currentComplaint.defendantFullName2 != '' ||
              currentComplaint.defendantFullName3 != ''
              ">
              Personas denunciadas
            </h3>
          </v-card-text>

          <p v-if="currentComplaint.defendantFullName != ''">
            <span class="soft_bold_title">Nombre:</span>
            {{ currentComplaint.defendantFullName }}
          </p>

          <p v-if="currentComplaint.defendantJob != ''">
            <span class="soft_bold_title">Ocupación:</span>
            {{ currentComplaint.defendantJob }}
          </p>
          <p v-if="currentComplaint.defendantFullName2 != ''">
            <span class="soft_bold_title">Nombre:</span>
            {{ currentComplaint.defendantFullName2 }}
          </p>
          <p v-if="currentComplaint.defendantJob2 != ''">
            <span class="soft_bold_title">Ocupación:</span>
            {{ currentComplaint.defendantJob2 }}
          </p>
          <p v-if="currentComplaint.defendantFullName3 != ''">
            <span class="soft_bold_title">Nombre:</span>
            {{ currentComplaint.defendantFullName3 }}
          </p>
          <p v-if="currentComplaint.defendantJob3 != ''">
            <span class="soft_bold_title">Ocupación:</span>
            {{ currentComplaint.defendantJob3 }}
          </p>
          <v-card-text>
            <h3>Acción denunciada</h3>
          </v-card-text>
          <p>
            <span class="soft_bold_title">Descripción de los hechos denunciados:</span>
            {{ currentComplaint.complainantDescription }}
          </p>
          <p>
            <span class="soft_bold_title">Lugar del incumplimiento normativo:</span>
            {{ currentComplaint.complainantPlace }}
          </p>
          <p>
            <span class="soft_bold_title">Departamento o área afectada:</span>
            {{ currentComplaint.complainantDepartment }}
          </p>
          <p>
            <span class="soft_bold_title"><span class="soft_bold_title">Fecha:</span></span>
            {{ currentComplaint.complaintDate }}
          </p>
          <p>
            <span class="soft_bold_title">Hora:</span>
            {{ currentComplaint.complaintTime }}
          </p>
          <v-card-text
            v-if="currentComplaint.witnessFullName || currentComplaint.witnessFullName2 || currentComplaint.witnessFullName3">
            <h3>Testigos</h3>
          </v-card-text>
          <p v-if="currentComplaint.witnessFullName != null">
            <span class="soft_bold_title">Nombre del testigo 1:</span>
            {{ currentComplaint.witnessFullName }}
          </p>
          <p v-if="currentComplaint.witnessJob != null">
            <span class="soft_bold_title">Cargo/puesto del testigo 1:</span>
            {{ currentComplaint.witnessJob }}
          </p>
          <p v-if="currentComplaint.witnessFullName2 != null">
            <span class="soft_bold_title">Nombre del testigo 2:</span>
            {{ currentComplaint.witnessFullName2 }}
          </p>
          <p v-if="currentComplaint.witnessJob2 != null">
            <span class="soft_bold_title">Cargo/puesto del testigo 2:</span>
            {{ currentComplaint.witnessJob2 }}
          </p>
          <p v-if="currentComplaint.witnessFullName3 != null">
            <span class="soft_bold_title">Nombre del testigo 3:</span>
            {{ currentComplaint.witnessFullName3 }}
          </p>
          <p v-if="currentComplaint.witnessJob3 != null">
            <span class="soft_bold_title">Cargo/puesto del testigo 3:</span>
            {{ currentComplaint.witnessJob3 }}
          </p>
          <v-card-text>
            <h3>Valor y documentación</h3>
          </v-card-text>
          <span v-if="currentComplaint.valuecomplaint != null" class="soft_bold_title">Valor estimado del daño:
          </span><br>
          <span v-if="currentComplaint.valuecomplaint != null"> {{ currentComplaint.valuecomplaint }}
          </span>


          <v-card-text v-if="currentComplaint.files && currentComplaint.files.length > 0">
            <h3>Archivos Adjuntos:</h3>
            <ul>
              <li v-for="(file, index) in currentComplaint.files" :key="index">
                <a :href="`${baseURL}/${file.file_path}`" target="_blank" download>
                  Descargar {{ file.file_name }}
                </a>
              </li>
            </ul>
          </v-card-text>

          <v-card-text>
            <h3>Fecha y estado</h3>
          </v-card-text>
          <p>
            <span class="soft_bold_title">Fecha de creación de la denuncia: </span>{{
              formatDate(currentComplaint.createdAt) }}
          </p>
          <p>
            <span class="soft_bold_title">Fecha de actualización de la denuncia: </span>
            {{ formatDate(currentComplaint.updatedAt) }}
          </p>
          <p v-if="currentComplaint.status != ''">
            <span class="soft_bold_title">Estado:</span>
            {{ currentComplaint.status }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showViewDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Diálogo para confirmar el borrado -->
    <v-dialog v-model="showDeleteDialog" persistent max-width="300px">
      <v-card>
        <v-card-title class="headline">Confirmación</v-card-title>
        <v-card-text>
          ¿Estás seguro de que quieres borrar esta denuncia?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDeleteDialog = false">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">Borrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false"> Cerrar </v-btn>
    </v-snackbar>
  </v-container>
</template>
<!-- ----------------------------------------------------------------------  -->
<script>
import MessagingComponent from '@/components/MessagingComponent.vue';
export default {
  components: {
    MessagingComponent,
  },
  data() {
    return {
      shapeStyle: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '10px',
        verticalAlign: 'middle'
      },
      showSearchFields: false,
      showResolutionDialog: null,
      showResolutionContentDialog: false,
      showDialog: false,
      tempComplaintId: null,
      resolutionText: '',
      messageCounts: {},
      selectedComplaintId: null,
      currentComplaintMessages: [],
      isMessagesPopupOpen: false,
      newMessage: '',
      searchId: '',
      searchCompanyName: '',
      searchDate: '',
      searchCity: '',
      searchEmail: '',
      isPopupOpen: false,
      selectedStatus: "",
      showCreateForm: false,
      currentComplaint: {
        street: "",
        postalCode: "",
        city: "",
        country: "",
        companyWorker: false,
        complainantAnonimous: false,
        department: "",
        complainantFullName: "",
        complainantPhoneNumber: "",
        complainantEmail: "",
        activeInfractions: [],
        selectedInfractions: "",
        typeComplainant: 0,
        defendantFullName: "",
        defendantFullName2: "",
        defendantFullName3: "",
        defendantJob: "",
        defendantJob2: "",
        defendantJob3: "",
        complainantDescription: "",
        complainantPlace: "",
        complainantDepartment: "",
        complaintDate: "",
        complaintTime: "",
        reportedComplainant: "",
        valuecomplaint: "",
        witnessFullName: "",
        witnessFullName2: "",
        witnessFullName3: "",
        witnessJob: "",
        witnessJob2: "",
        witnessJob3: "",
        termsAccepted: false,
        status: "",
        active: false,
        files: [],
        createdAt: "",
        updatedAt: "",
      },
      rules: {
        required: (value) => !!value || "Requerido",
        requiredCheckbox: (value) => value || "Debe aceptar los términos",
      },
      valid: true,
      complaints: [],
      conversations: {},
      messages: {},
      resellerInfo: this.$store.state.resellerInfo,
      showViewDialog: false,
      showEditDialog: false,
      infractions: [],
      infractionsArr: [],
      checkedInfractions: [],
      selectedInfractions: [],
      newInfraction: {
        selectedInfraction: null,
      },
      valuecomplaintOptions: [
        "De 1€ a 1000 €",
        "De 1.001 € a 10.000 €",
        "De 10.001 € a 12.000 €",
        "De 12.001 € a 1 Millón €",
        "+ de 1 Millón de €",
        "No sabe cuantificarlo",
      ],
      showDeleteDialog: false,
      complaintToDelete: null,
      snackbar: {
        show: false,
        color: "success",
        message: "",
      },
      baseURL: process.env.VUE_APP_UPLOAD_BASE_URL,

    };
  },

  mounted() {
    this.fetchComplaints();
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    },
    isPopupView() {
      // Modificar para conseguir vista de popup o no
      return true;
    },
    complaintCounts() {
      return this.countComplaintsByStatus();
    },
    // Filtrar denuncias según diversas variables
    filteredComplaints() {
      return this.complaints.filter(complaint => {
        const matchesId = complaint.complaintId.toString().includes(this.searchId);
        const matchesCompanyName = complaint.Company.company_name.toLowerCase().includes(this.searchCompanyName.toLowerCase());
        const matchesDate = complaint.complaintDate.toLowerCase().includes(this.searchDate);
        const matchesCity = complaint.city.toLowerCase().includes(this.searchCity.toLowerCase());
        // const matchesEmail = complaint.complainantEmail.toLowerCase().includes(this.searchEmail);
        const email = complaint.complainantEmail || ''; // Usar cadena vacía si complainantEmail no existe
        const matchesEmail = email.toLowerCase().includes(this.searchEmail.toLowerCase());
        return matchesId && matchesCompanyName && matchesDate && matchesCity && matchesEmail;
      });
    },
  },
  methods: {

    /**
 * Métodos auxiliares
 */
    getStatusColor(status) {
      switch (status) {
        case 'Proceso no iniciado': return 'yellow darken-2';
        case 'En proceso de revisión': return 'primary';
        case 'Pendiente de respuesta': return 'orange darken-4';
        default: return 'green darken-4';
      }
    },
    // Contar el número de mensajes en un hilo de denuncia
    async countMessages(complaintId) {
      try {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/${complaintId}/message-count`;
        const response = await this.$axios.get(apiUrl);
        if (response.data.count) {
          return response.data.count;
        }
      } catch (error) {
        console.error('Error recuperando el contador de mensajes:', error);
        return 0; // Return 0 in case of an error
      }
    },

    // Actualizar el contador de mensajes
    async updateMessageCounts() {
      for (const complaint of this.complaints) {
        const count = await this.countMessages(complaint.complaintId);
        this.messageCounts[complaint.complaintId] = count;
      }
    },

    // Conversión de los id de infractions en la tabla `complaints` a texto vía `pdd_infractions`
    getCheckedInfractions(complaint) {
      // Hay que vaciar la variable cada vez que se ejecuta la función
      this.checkedInfractions = [];
      this.infractionsArr = complaint.selectedInfractions.split(",");
      for (let i = 0; i < this.infractionsArr.length; i++) {
        let toInt = parseInt(this.infractionsArr[i]);
        this.checkedInfractions[i] = this.infractions[toInt - 1].text;
      }
    },

    // Abrir la ventana de mensajes
    openMessagesPopup(complaint) {
      this.selectedComplaintId = complaint.complaintId;
      this.currentComplaintMessages = []; // Reiniciar la variable
      this.isMessagesPopupOpen = true;
      this.fetchMessages(complaint.complaintId);
    },

    // Formato para imprimir las fechas
    formatDate(date) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return new Date(date).toLocaleDateString("es-ES", options);
    },

    // Control del popup de la columna con el icono de usuario
    openPopup(complaint) {
      this.currentComplaint = { ...complaint };
      this.isPopupOpen = true;
    },

    // Contar número de denuncias
    countComplaintsByStatus() {
      let count = {
        pending: 0,
        under_review: 0,
        pending_response: 0,
        resolved: 0
      };
      this.complaints.forEach(complaint => {
        if (complaint.status === 'Proceso no iniciado') {
          count.pending++;
        } else if (complaint.status === 'En proceso de revisión') {
          count.under_review++;
        } else if (complaint.status === 'Pendiente de respuesta') {
          count.pending_response++;
        } else if (complaint.status === 'Proceso cerrado') {
          count.resolved++;
        }
      });
      return [count.pending, count.under_review, count.pending_response, count.resolved];
    },

    // Limpiar los campos de búsqueda
    resetSearchFields() {
      this.searchId = '';
      this.searchCompanyName = '';
      this.searchDate = '';
      this.searchCity = '';
      this.searchEmail = '';
    },

    /**
     * Métodos CRUD
     */

    // Recuperar las denuncias
    async fetchComplaints() {
      try {
        const userInfo = this.userInfo;
        if (!userInfo || !userInfo.resellerId || !userInfo.pddRoleId) {
          throw new Error("Información del usuario no disponible");
        }
        const { resellerId } = userInfo;

        await this.fetchInfractions();

        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/reseller/${resellerId}`;
        const response = await this.$axios.get(apiUrl);

        // Suponiendo que las denuncias ahora incluyen el nombre de la compañía y los textos de las infracciones
        const complaints = response.data.map(complaint => {
          // Procesar los textos de las infracciones si es necesario
          const infractionTexts = complaint.selectedInfractions.split(",").map(infractionId => {
            const toInt = parseInt(infractionId);
            return this.infractions[toInt - 1]?.text || 'Infracción desconocida'; // Por si no se encuentra
          });

          return {
            ...complaint,
            infractionTexts, // Añadir los textos de la infracción al objeto complaint
          };
        });

        this.complaints = complaints;
        this.complaints.forEach(complaint => {
          this.getCheckedInfractions(complaint); // Asumiendo que esto es necesario para el procesamiento adicional
        });
        this.updateMessageCounts();
      } catch (error) {
        console.error(error);
      }
    },

    // async fetchComplaints() {
    //   try {
    //     const userInfo = this.userInfo;
    //     if (!userInfo || !userInfo.resellerId || !userInfo.pddRoleId) {
    //       throw new Error("Información del usuario no disponible");
    //     }
    //     const { resellerId } = userInfo;
    //     const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/reseller/${resellerId}`;
    //     const response = await this.$axios.get(apiUrl);
    //     console.log('response', response);
    //     // Retrieve the company names associated with each complaint
    //     const complaintsWithCompanyNames = await Promise.all(
    //       response.data.map(async (complaint) => {
    //         const companyId = complaint.companyId;
    //         const companyUrl = `${process.env.VUE_APP_API_BASE_URL}/companies/${companyId}`;
    //         const companyResponse = await this.$axios.get(companyUrl);
    //         const companyName = companyResponse.data.company_name;
    //         return {
    //           ...complaint,
    //           companyName: companyName,
    //         };
    //       })
    //     );
    //     for (let complaint of complaintsWithCompanyNames) {
    //       const infractionTexts = complaint.selectedInfractions.split(",").map(infractionId => {
    //         const toInt = parseInt(infractionId);
    //         return this.infractions[toInt - 1]?.text || 'Infracción desconocida'; // Por si no se encuentra
    //       });

    //       complaint.infractionTexts = infractionTexts; // Añadir los textos de la infracción al objeto complaint
    //     }
    //     this.complaints = complaintsWithCompanyNames;
    //     this.complaints.forEach(complaint => {
    //       this.getCheckedInfractions(complaint);
    //     });
    //     console.log('complaints', this.complaints);
    //     this.updateMessageCounts();
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    // Recuperar las infracciones
    async fetchInfractions() {
      try {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/pdd-infractions`;
        const response = await this.$axios.get(apiUrl);
        this.infractions = response.data; // Asegúrate de que la API devuelva las denuncias en este formato
      } catch (error) {
        this.snackbar.message = "Error en la petición de tipos de denuncia";
        this.snackbar.color = "error";
        this.snackbar.show = true;
        console.error(error);
      }
    },

    // Recuperar los mensajes
    async fetchMessages(complaintId) {
      try {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/${complaintId}/messages`;
        const response = await this.$axios.get(apiUrl);
        this.currentComplaintMessages = response.data;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },

    // Recuperar la resolución de una denuncia
    async fetchResolution(complaintId) {
      try {

        const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/resolutions/complaint/${complaintId}`);
        this.resolutionText = response.data.resolutionText; // Assuming the response contains a resolutionText field
        this.showResolutionContentDialog = true; // To show the dialog
      } catch (error) {
        this.snackbar.message = "Error en la recuperación de la resolución";
        this.snackbar.color = "error";
        this.snackbar.show = true;
        console.error('Failed to fetch resolution:', error);
      }
    },

    /**
     * Actualizar el estado de la denuncia
     */
    async updateState(id) {
      try {
        const newStatus = this.selectedStatus;
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/${id}`;
        const response = await this.$axios.put(
          apiUrl,
          { status: newStatus },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const complaintToUpdate = this.complaints.find(
          (complaint) => complaint.complaintId === id
        );
        if (complaintToUpdate) {
          complaintToUpdate.status = newStatus;
        }
        this.selectedStatus = "";
      } catch (error) {
        console.error(error);
      }
    },

    // Guardar resolución
    async saveResolution() {
      if (!this.tempComplaintId) {
        this.snackbar.message = "Error al recuperar el identificador de la denuncia";
        this.snackbar.color = "error";
        this.snackbar.show = true;
        // You may want to show a user-friendly error message here
        return;
      }
      const payload = {
        complaintId: this.tempComplaintId,
        resolutionText: this.resolutionText,
      };
      try {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/resolutions`;
        const response = await this.$axios.post(apiUrl, payload);
        // Actualizar el estado de la denuncia a 'Proceso cerrado'
        const statusUpdatePayload = { status: 'Proceso cerrado'.trim() };
        const complaintApiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/${this.tempComplaintId}`;
        const updateResponse = await this.$axios.put(complaintApiUrl, statusUpdatePayload, {
          headers: { "Content-Type": "application/json" },
        });
        this.showResolutionDialog = false;
        const newStatus = 'Proceso cerrado';
        const complaintToUpdate = this.complaints.find(
          (complaint) => complaint.complaintId === this.tempComplaintId
        );
        if (complaintToUpdate) {
          complaintToUpdate.status = newStatus;
        }

        this.selectedStatus = "";
        this.resolutionText = '';
        this.snackbar.message = "Resolución creada correctamente";
        this.snackbar.color = "success";
        this.snackbar.show = true;
      } catch (error) {
        this.snackbar.message = "Error al crear la resolución";
        this.snackbar.color = "error";
        this.snackbar.show = true;
        console.error(error);
      }
    },

    /**
     * Abrir los diálogos de denuncia
     */

    // Ver denuncia
    viewComplaint(complaint) {
      this.currentComplaint = { ...complaint };
      this.getCheckedInfractions(complaint);
      this.showViewDialog = true;
    },
    // Editar denuncia
    editComplaint(complaint) {
      this.currentComplaint = { ...complaint };
      this.showEditDialog = true;
    },
    // Borrar denuncia
    deleteComplaint(complaint) {
      this.complaintToDelete = complaint;
      this.showDeleteDialog = true;
    },
    resetcurrentComplaint() {
      this.currentComplaint = {
        title: "",
        description: "",
        files: [],
        // Inicializa aquí otros campos necesarios
      };
    },
    openDialog(complaintId) {
      this.tempComplaintId = complaintId;
      this.showDialog = true;
    },
    openResolutionDialog(complaint) {
      this.tempComplaintId = complaint;
      this.showResolutionDialog = true;
    },
    confirmUpdateState() {
      this.showDialog = false; // Close the dialog
      this.updateState(this.tempComplaintId); // Proceed with the state update
      this.tempComplaintId = null;
    },
  },
};
</script>

<style scoped>
.buttonCrudActions {
  margin-left: 10px;
  margin-top: 10px;
}

.soft_bold_title {
  font-weight: bold;
  color: #888888;
  min-width: 100px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-content {
  max-height: 300px;
  /* Adjust based on your requirement */
  overflow-y: auto;
}
</style>
