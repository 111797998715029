<template>
    <v-container>
        <v-card class="mb-4" elevation="2">
            <h3>Aviso Legal</h3>
            <v-card-text>
                De conformidad con lo dispuesto en los artículos 10 y 11 de la Ley 34/2002, de 11 de julio, de Servicios
                de
                la Sociedad de la Información y de Comercio Electrónico se pone a disposición de los usuarios y
                visitantes
                la información legal relativa a la Entidad propietaria del sitio web ubicado en la dirección de Internet
                https://portaldenuncias.online
            </v-card-text>
        </v-card>

        <v-card class="mb-4" elevation="2">
            <h3>Titular del portal de denuncias</h3>
            <v-card-text>
                <p>Denominación social: J. AGUILO SISTEMAS Y REDES S.L.</p>
                <p>CIF: B07934318.</p>
                <p>Domicilio: CAMINO NOU 7, BAJOS 2 07009 PALMA DE MALLORCA, BALEARES</p>
                <p>Dirección de correo electrónico: dpo@syr.es.</p>
                <p>Teléfono: 971249957</p>
                <p>Inscrito en el Registro Mercantil Islas Baleares al Tomo 1627, Folio 123, Hoja PM 31250, Sección 8.
                </p>
                <p>Actividad: Informática</p>
            </v-card-text>
            <v-card-text>
                <p>Denominación social: PROTOCOLOS DE SEGURIDAD INFORMATICA PROTECMIR S.L.</p>
                <p>CIF/NIF: B87193207</p>
                <p>Domicilio: PASEO DE LA CASTELLANA, Nº 40, 8ª PLANTA 28046 MADRID, MADRID</p>
                <p>Dirección de correo electrónico: protecmirlegal@protecmir.com</p>
                <p>Teléfono: 971918848</p>
                <p>Inscrito en el Registro Mercantil MADRID Tomo 33072, Folio 165, Hoja M 595286, Libro , SECCIÓN 8.</p>
                <p>Actividad: Consultoría de Empresas </p>
            </v-card-text>
        </v-card>

        <v-card class="mb-4" elevation="2">
            <h3>Condiciones de uso</h3>
            <v-card-text>
                Para navegar en este portal de denuncias como visitante o para registrarse y poder acceder a los
                servicios ofrecidos por J. AGUILO SISTEMAS Y REDES S.L. y PROTOCOLOS DE SEGURIDAD INFORMATICA PROTECMIR
                S.L., se deberán aceptar las siguientes condiciones de servicio y uso:
                <ul>
                    <li>a) El usuario asume las presentes condiciones generales de uso y servicio del portal de
                        denuncias https://portaldenuncias.online/ Si el usuario no acepta las presentes condiciones, no
                        podrá hacer uso de los servicios y contenidos del portal de denuncias
                        https://portaldenuncias.online/</li>
                    <li>b) Las presentes condiciones de uso regulan el acceso y utilización del portal de denuncias
                        https://portaldenuncias.online/ La utilización del portal de denuncias le atribuye la condición
                        de usuario desde el acceso e inicio de la navegación en la misma. Desde el momento de acceso a
                        cualquiera de sus contenidos, el usuario acepta de manera expresa las presentes condiciones
                        generales. El usuario acepta las condiciones particulares aplicables de los diferentes servicios
                        o funcionalidades ofertados en el portal de denuncias https://portaldenuncias.online/ a la que
                        accede.</li>
                    <li>c) A través del portal de denuncias https://portaldenuncias.online/ se facilita a los usuarios
                        el acceso y la utilización de diversa información y diversos servicios.</li>
                    <li>d) El usuario se compromete a realizar un uso adecuado de los contenidos y servicios del portal
                        de denuncias https://portaldenuncias.online/. Este uso debe realizarse de conformidad con la
                        Ley, las buenas costumbres, el orden público y con lo dispuesto en las presentes condiciones
                        generales.</li>
                    <li>e) Con carácter general, para la prestación de los servicios y el acceso a la información del
                        portal de denuncias https://portaldenuncias.online/ no se exige la previa suscripción o registro
                        del usuario. No obstante, se condiciona la utilización de algunos de los servicios del portal de
                        denuncias https://portaldenuncias.online/ a la previa cumplimentación del correspondiente
                        registro o formulario de recogida de datos de carácter personal del usuario. El citado registro
                        se efectuará en la forma expresamente indicada en el propio servicio o en las condiciones
                        particulares que, en su caso, lo regulen. Si el usuario tiene la condición de denunciante tiene
                        la opción de interponer denuncias anónimas.</li>
                    <li>f) El usuario asume la obligación de no realizar ninguna actuación que pueda dañar, inutilizar,
                        hacer inaccesible o deteriorar los contenidos, los servicios y/o impedir un normal uso del
                        portal de denuncias https://portaldenuncias.online/ por otros usuarios. En particular, y a
                        título meramente enunciativo y no limitativo, el usuario se compromete a:</li>
                    <p>No realizar ninguna acción que pueda afectar, destruir, alterar, inutilizar o dañar datos de
                        carácter personal, programas o documentos electrónicos que se encuentren en el portal de
                        denuncias.</p>
                    <p>No introducir, almacenar o difundir ningún programa de ordenador, datos, virus o código que
                        sea susceptible de causar daños en el portal de denuncias, en cualquiera de los servicios, o
                        en cualquiera de los equipos, sistemas o redes de la entidad, de cualquier otro usuario y/o
                        de cualquier proveedor de la entidad.</p>
                </ul>
            </v-card-text>
        </v-card>
        <v-card class="mb-4" elevation="2">
            <h3>Modificaciones</h3>
            <v-card-text>
                La entidad se reserva el derecho a modificar unilateralmente en cualquier momento las presentes
                condiciones debido a la existencia de nuevas circunstancias económicas y/o comerciales que así lo
                aconsejen, así como por la modificación, evolución y promulgación de leyes, reglamentos y normas de
                aplicación que afecten a la prestación del servicio y/o aspectos conexos a ello. En estos casos, se
                procederá a su publicación y aviso con la máxima antelación posible. De igual modo, se reserva el
                derecho a modificar, unilateralmente en cualquier momento la presentación y configuración del portal de
                denuncias.
            </v-card-text>
        </v-card>

        <v-card class="mb-4" elevation="2">
            <h3>Enlaces con Terceros</h3>
            <v-card-text>
                El presente Aviso Legal se refiere únicamente al portal de denuncias https://portaldenuncias.online/, y
                no se aplica a los enlaces o a las páginas web de terceros accesibles a través del portal de denuncias.
                La entidad no es responsable del contenido de ninguna de las páginas web de destino de un enlace.
            </v-card-text>
        </v-card>

        <v-card class="mb-4" elevation="2">
            <h3>Propiedad Intelectual e Industrial</h3>
            <v-card-text>
                <p>Todos los contenidos del portal de denuncias https://portaldenuncias.online/ son titularidad exclusiva
                de los titulares y, con carácter enunciativo, que no limitativo, el diseño gráfico, código fuente,
                logos, textos, gráficos, ilustraciones, fotografías, y demás elementos que aparecen en el portal de
                denuncias. Así mismo los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos
                en el portal de denuncias https://portaldenuncias.online/ están protegidos por la Ley de propiedad
                intelectual e industrial. Corresponde a la entidad el ejercicio exclusivo de los derechos de explotación
                de la propiedad intelectual mencionada, en cualquier forma y, en especial, los derechos de reproducción,
                distribución y comunicación pública. El usuario tiene prohibido cualquier uso no consentido total o
                parcial de cualquiera de los contenidos del portal de denuncias https://portaldenuncias.online/ que
                integran los derechos de propiedad intelectual o industrial de los titulares sobre el portal de
                denuncias y/o sus contenidos.</p>
                <p>La entidad se reserva el derecho a realizar cualquier tipo de acción legal contra cualquier usuario que
                realice una acción que implique reproducción, distribución, comercialización, transformación y, en
                general, cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los
                contenidos del portal de denuncias, y que constituya una infracción de los derechos de propiedad
                intelectual y/o industrial de la misma.</p>
            </v-card-text>
        </v-card>

        <v-card class="mb-4" elevation="2">
            <h3>Notificaciones</h3>
            <v-card-text>
                A los efectos de las presentes condiciones generales, y para cualquier comunicación que sea precisa
                entre la entidad y el usuario, este último deberá hacer uso del correo electrónico
                protecmirlegal@protecmir.com. Las comunicaciones de la entidad al usuario se realizarán conforme a los
                datos de carácter personal aportados por este último al registrarse en el portal de denuncias
                https://portaldenuncias.online/ El usuario acepta expresamente y para todas las comunicaciones
                relacionadas con la utilización del portal de denuncias y/o la contratación de los servicios ofertados
                en la misma, la utilización del correo electrónico como procedimiento válido para la remisión de estas
                comunicaciones.
            </v-card-text>
        </v-card>

        <v-card class="mb-4" elevation="2">
            <h3>Legislación Aplicable</h3>
            <v-card-text>
                Las relaciones establecidas entre los titulares del portal de denuncias https://portaldenuncias.online/
                y el usuario se regirán por la normativa española vigente, y la resolución de cualquier posible
                controversia que pueda surgir quedará sometida a los Juzgados y Tribunales competentes. En el caso de
                que el usuario tenga la condición de consumidor, serán competentes los Juzgados y Tribunales que
                correspondan según lo previsto en la normativa de consumidores vigente.
            </v-card-text>
        </v-card>
    </v-container>

    <footer-component class="item"></footer-component></template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
export default {
    name: "App",
    components: {
        FooterComponent,
    },
}
</script>