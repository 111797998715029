<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Regenerar contraseña</h1>
        <p>
          Introduzca su dirección de email a continuación. <br />
          Si está en nuestros registros, le enviaremos un email para que pueda
          reiniciar su contraseña.
        </p>
        <v-form id="forgotPasswordForm" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
              v-model="email"
              label="Dirección de email"
              type="email"
              required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn id="fpButton" color="success" class="mb-2" type="submit">
                Enviar email
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-alert
          class="forgot-message"
          color="success"
          v-show="showMessage"
        >
          Dirección de correo enviada. Si tenemos un email en nuestros
          registros, le enviaremos un email de recuparación. Por favor, espere
          unos minutos y revise su carpeta de spam si no lo encuentra.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      showMessage: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/forgot-password`;
        const response = await this.$axios.post(apiUrl, { email: this.email });
        this.showMessage = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
