<template>
    <v-container class="maincontainer">
        <p class="text-h4">FAQ</p>
        <v-card class="mb-4" elevation="2">
            <v-card-title>¿Puedo hacer que mi denuncia sea anónima?</v-card-title>
            <v-card-text>
                <p>
                    Sí, encontrará la opción en el formulario para marcar su denuncia como anónima. No será necesario añadir
                    su
                    nombre o teléfono en ese caso.
                </p>
                <p>
                    Será necesario, en todo caso, añadir una dirección de correo electrónico para permitir el acceso a la
                    plataforma. Si desea que no se le pueda identificar por su dirección de correo, le recomendamos que se
                    cree
                    una cuenta adicional que no le identifique.
                </p>
                <p>En cualquier caso, tan solo la persona responsable del Sistema Interno de Información podrá ver su
                    dirección
                    de correo, así como, en caso de proveerlo, su nombre y teléfono.</p>
            </v-card-text>
        </v-card>
        <v-card class="mb-4" elevation="2">
            <v-card-title>
                ¿Qué ocurrirá tras rellenar el formulario de denuncia?
            </v-card-title>
            <v-card-text>
                <p>Recibirá, en la dirección de correo electrónico que haya proporcionado, un correo donde se incluirá una
                    copia en PDF del contenido de su denuncia, así como las credenciales de acceso a la plataforma (usuario
                    y
                    contraseña).</p>
                <p>
                    Le recomendamos que establezca una nueva contraseña, lo que podrá hacer a través de
                    <v-btn :href="forgotPasswordLink" target="_blank" density="compact" class="text-none text-body-1">
                        este enlace
                    </v-btn>.
                </p>
            </v-card-text>
        </v-card>
        <v-card class="mb-4" elevation="2">
            <v-card-title>
                ¿Qué podré hacer una vez haya ingresado en la plataforma?
            </v-card-title>
            <v-card-text>
                Dentro de la plataforma podrá consultar el texto de su denuncia, acceder a la aplicación de mensajería, y
                adjuntar nuevos archivos en soporte de su denuncia.
            </v-card-text>
        </v-card>
        <v-card class="mb-4" elevation="2">
            <v-card-title>
                ¿Cómo funciona la aplicación de mensajería?
            </v-card-title>
            <v-card-text>
                <p>
                    Encontrará un botón que le permitirá abrir dicha aplicación. Desde aquí podrá enviar mensajes que
                    recibirá únicamente la persona Responsable del Sistema Interno de Información.
                </p>
                <p>
                    Si lo desea, también puede adjuntar nuevos archivos que den evidencia a su denuncia, usando el botón que
                    encontrará en la página.
                </p>
            </v-card-text>
            <v-card class="mb-4" elevation="2">
                <v-card-title>
                    ¿Cómo será procesada su denuncia?
                </v-card-title>
                <v-card-text>
                    <p>La única persona que podrá ver toda la información proporcionada en la denuncia, incluyendo su
                        dirección
                        de correo y, de haberlos proporcionado (no es obligatorio), su nombre y teléfono, es aquella a quien
                        su empresa haya designado como Responsable del Sistema Interno de Información. Esta persona podrá
                        comunicarle un cambio en el estado de su denuncia, y emitir una resolución sobre la misma.</p>
                    <p>Los cambios de estado y una eventual resulución le serán notificados en la dirección de correo que
                        nos haya proporcionado.</p>
                    <p>La persona designada por su empresa como Delegado de Protección de Datos (DPO) será la encargada de
                        velar por la protección de sus datos personales.</p>
                </v-card-text>
            </v-card>
        </v-card>
    </v-container>

    <footer-component class="item"></footer-component>
</template>
<script>
import FooterComponent from '@/components/FooterComponent.vue';
export default {
    name: "App",
    components: {
        FooterComponent,
    },
    computed: {
        forgotPasswordLink() {
            return `${process.env.VUE_APP_DOMAIN_BASE_URL}/forgot-password`;
        }
    },
}
</script>
<style scoped>
.maincontainer {
    min-height: 100vh;
}
</style>