<template>
    <v-col cols="12" md="6">
        <v-card class="mt-4 px-2 py-4" rounded="lg" outlined>
            <div class="soft_bold_title px-2 py-4">Mensajes: {{ messageCounts[complaint.complaintId] || 0 }}</div>
            <v-btn color="primary" @click="openMessagesPopup(complaint)">
                <v-icon class="mr-4">mdi-comment-outline</v-icon>
                Ver mensajes
            </v-btn>
        </v-card>
        <v-dialog v-model="isMessagesPopupOpen" persistent max-width="600px">
            <v-card>
                <v-row class="pa-2 sticky-button">
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn icon @click="closeMessagesPopup(complaint)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-title>Conversaciones</v-card-title>
                <v-card-text class="dialog-content">
                    <div v-for="message in currentComplaintMessages" :key="message.id" class="my-1">
                        <v-row :class="message.senderId === userInfo.userId ? 'justify-end' : 'justify-start'">
                            <v-col cols="12" sm="8">
                                <div class="d-flex flex-column"
                                    :class="message.senderId === userInfo.userId ? 'align-end' : 'align-start'">
                                    <div class="py-2 px-3 my-1"
                                        :class="message.senderId === userInfo.userId ? 'bg-green lighten-4' : 'bg-light-blue lighten-4'"
                                        style="border-radius: 10px;">
                                        <p class="subtitle-2 mb-1">{{ message.senderUsername }}</p>
                                        <!-- <p>{{ message.content }}</p>
                                        <p v-html="message.content"></p> -->
                                        <div v-html="message.content" class="message-content"></div>
                                        <p class="caption grey--text">{{ formatDate(message.timestamp) }}</p>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-text-field v-model="newMessage" label="Escribe un mensaje" outlined></v-text-field>
                <v-card-actions>
                    <v-btn @click="sendMessage">Enviar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false"> Cerrar </v-btn>
        </v-snackbar>
    </v-col>
</template>
  
<script>
import io from 'socket.io-client';

export default {
    name: 'MessagingComponent',
    props: {
        complaint: Object,
        userInfo: Object,
    },
    data() {
        return {
            socket: null,
            isMessagesPopupOpen: false,
            currentComplaintMessages: [],
            newMessage: '',
            messageCounts: {},
            snackbar: {
                show: false,
                color: "success",
                message: "",
            },
            isMailSent: false,
        };
    },
    methods: {
        // Formato para imprimir las fechas
        formatDate(date) {
            const options = { day: "2-digit", month: "2-digit", year: "numeric" };
            return new Date(date).toLocaleDateString("es-ES", options);
        },
        // Contar el número de mensajes en un hilo de denuncia
        async countMessages(complaintId) {
            try {
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/${complaintId}/message-count`;
                const response = await this.$axios.get(apiUrl);
                return response.data.count;
            } catch (error) {
                console.error('Error fetching message count:', error);
                return 0; // Return 0 in case of an error
            }
        },

        // Actualizar el contador de mensajes
        async updateMessageCounts() {
            if (this.complaint && this.complaint.complaintId) {
                const count = await this.countMessages(this.complaint.complaintId);
                this.messageCounts[this.complaint.complaintId] = count;
            }
        },

        // Abrir la ventana de mensajes
        openMessagesPopup(complaint) {
            this.selectedComplaintId = complaint.complaintId;
            this.currentComplaintMessages = []; // Reiniciar la variable
            this.isMessagesPopupOpen = true;
            this.socket = io(process.env.VUE_APP_SOCKET_URL);
            this.socket.emit('joinConversation', this.selectedComplaintId);
            this.socket.on('newMessage', (message) => {
                this.currentComplaintMessages.push(message);
                if (message.complaintId === this.selectedComplaintId) {

                    this.currentComplaintMessages.push(message);
                    this.fetchMessages(message.complaintId);
                }
            });
            this.fetchMessages(complaint.complaintId);
        },
        // Cerrar la ventana de mensajes
        closeMessagesPopup(complaint) {
            this.isMessagesPopupOpen = false;
            this.selectedComplaintId = complaint.complaintId;
            if (this.selectedComplaintId) {
                this.socket.emit('leaveConversation', this.selectedComplaintId);
                this.socket.disconnect(); // Desconectar el socket cuando se cierra la conversación
                this.socket = null;
            }
        },

        // Recuperar los mensajes
        async fetchMessages(complaintId) {
            try {
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/complaints/${complaintId}/messages`;
                const response = await this.$axios.get(apiUrl);

                // Assign response data directly without processing fileUrls
                this.currentComplaintMessages = response.data;
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        },

        // Enviar mensaje
        async sendMessage() {
            if (this.newMessage.trim() === '') return;

            try {
                const formData = new FormData();
                formData.append('senderId', this.userInfo.userId);
                formData.append('content', this.newMessage);
                formData.append('complaintId', this.selectedComplaintId);

                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/messages`;
                const response = await this.$axios.post(apiUrl, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                let messageContent = this.newMessage;
                this.socket.emit('message_sent', { content: messageContent, complaintId: this.selectedComplaintId });

                this.currentComplaintMessages.push({
                    senderUsername: this.userInfo.username,
                    content: messageContent,
                    timestamp: new Date() // Consider using server timestamp if available
                });

                this.newMessage = '';
                this.fetchMessages(this.selectedComplaintId);
                await this.updateMessageCounts();
                // Enviar mail tras enviar el primer mensaje, un mail por sesión
                if (!this.isMailSent) {
                    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/messages/${this.selectedComplaintId}/send-email`;
                    await this.$axios.post(apiUrl);  // Asume que no necesitas enviar datos adicionales en el cuerpo de la petición

                    this.snackbar = {
                        show: true,
                        color: 'success',
                        message: 'Email enviado con éxito.',
                    };

                    this.isMailSent = true;
                }
            } catch (error) {
                this.snackbar.message = "Error al enviar el mensaje";
                this.snackbar.color = "error";
                this.snackbar.show = true;
                console.error('Error al enviar el mensaje:', error);
                if (error.response) {
                    console.error('Response error:', error.response);
                }
            }
        },
    },
    mounted() {
        // Fetch the initial message counts if necessary
        this.updateMessageCounts();



    },
    beforeUnmount() {
        if (this.selectedComplaintId && this.socket) {
            this.socket.emit('leaveConversation', this.selectedComplaintId);
        }
        if (this.socket) {
            this.socket.disconnect();
        }
    },
};
</script>
  
<style scoped>
.sticky-button {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>
  