<template>
    <!-- Primera sección -->
    <vue-scroll-snap v-if="!showForm">
        <v-container fluid class="pt-0 mt-0 h-screen item"
            style="background: url('/images/alesia-kaz-VWcPlbHglYc-unsplash.jpg') center / cover no-repeat;">
            <v-row class="h-screen" align="center" justify="center">
                <v-col cols="12" sm="10" md="8" lg="6" class="text-center">
                    <v-card class="pa-4 fade-in responsive-card" color="rgba(255, 255, 255, 0.9)" elevation="2"
                        rounded="lg">
                        <h1 class="text-h2 slide-right"><v-img :src="resellerLogo" max-width="25%"
                                class="center-image"></v-img>
                        </h1>
                        <div class="text-subtitle-1 slide-left responsive-text">
                            <h3>Portal de denuncias de {{ resellerName }}</h3>
                            <p>{{ company.textSnippet }}</p>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>


        <!-- Segunda sección -->
        <v-container fluid class="h-screen bg-grey-lighten-2 item"
            style="background: url('/images/gabrielle-henderson-HJckKnwCXxQ-unsplash.jpg') center / cover no-repeat;">
            <v-row class="align-center justify-center">
                <!-- Text column centered -->
                <v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
                    <!-- <animated-component> -->
                    <v-card class="pa-4" color="rgba(255, 255, 255, 0.9)" elevation="2" rounded="lg">
                        <div v-if="company">
                            Empresa/Entidad objeto de la denuncia: <b>{{ company.company_name }}</b>
                        </div>
                        <h2>Información para el usuario</h2>
                        <p>
                            El Sistema Interno de Información de <b>{{ company.company_name }}</b>
                            es el cauce para comunicar, de forma confidencial y efectiva, cualquier información sobre
                            hechos que impliquen un incumplimiento normativo y/o una conducta ilícita cometidos por
                            <b>{{ company.company_name }}</b> o su personal, que hayan sido conocidos en el marco de una
                            relación laboral o profesional mantenida con nuestra organización de conformidad con lo
                            dispuesto en la Ley 2/2023, de 20 de febrero.
                        </p>
                        <p>
                            Haga click en el botón situado bajo estas líneas para acceder al formulario de denuncias.
                        </p>
                        <p>
                            Por favor, rellene, marque o seleccione, según proceda, todos los campos marcados con un
                            asterisco ( * ).
                        </p>
                        <p>
                            Si desea que su denuncia permanezca anónima, seleccione la opción procedente en el
                            formulario. La dirección de correo es obligatoria, por lo que le recomendamos que utilice o
                            cree una cuenta de correo que no facilite su identificación.
                        </p>

                    </v-card>
                    <!-- </animated-component> -->
                    <v-card class="pa-4" color="rgba(255, 255, 255, 0.9)" elevation="2" rounded="lg">
                        <h2>Después de la denuncia</h2>
                        <p>Tras cumplimentar el formulario, recibirá un correo en la dirección que nos haya indicado,
                            donde se le proporcionará un usuario y contraseña para acceder a la plataforma y
                            realizar un seguimiento de su denuncia.</p>
                    </v-card>
                    <v-card class="pa-4" color="rgba(255, 255, 255, 0.9)" elevation="2" rounded="lg"
                        style="width: 100%;">
                        <h2>Más información</h2>
                        <v-btn href="https://boe.es/boe/dias/2023/02/21/pdfs/BOE-A-2023-4513.pdf" target="_blank">
                            <p>Texto legal</p>
                            (BOE).
                        </v-btn>
                    </v-card>

                    <!-- Botón -->
                    <v-btn size="x-large" color="primary" class="my-4 multi-line-btn" @click="showForm = true">
                        <v-icon left>mdi-book-open</v-icon>
                        <div class="button-text-container">
                            <p class="ml-2">Abrir formulario</p>
                        </div>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <footer-component v-if="!showForm" class="item"></footer-component>
    </vue-scroll-snap>

    <!-- Cargar formulario -->
    <v-container fluid v-if="showForm">
        <form-complaints :company="company"></form-complaints>
        <footer-component v-if="showForm"></footer-component>
    </v-container>
</template>

<script>
// import AnimatedComponent from '../components/AnimatedComponent.vue';
import VueScrollSnap from 'vue-scroll-snap';
import FooterComponent from '@/components/FooterComponent.vue'
import FormComplaints from '../components/FormComplaints.vue';
export default {
    data() {
        return {
            hashedCompany: 'null',
            company: {},
            showForm: false,

        };
    },
    created() {
        this.hashedCompany = this.$route.params.hashedCompanyId;
        if (this.hashedCompany) {
            this.fetchCompanyData();
        }
    },
    computed: {
        resellerName() {
            return this.company.Reseller ? this.company.Reseller.name : 'Default Name';
        },
        resellerLogo() {
            return this.company.Reseller ? this.company.Reseller.logoImagePath : '';
        }
    },
    methods: {
        async fetchCompanyData() {
            try {
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/companies/hashed/${this.hashedCompany}`;
                const response = await this.$axios.get(apiUrl);
                if (response.status === 200) {
                    this.company = response.data;
                } else {
                    this.$router.push('/no-encontrado');
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    console.error(error);
                    this.$router.push('/no-encontrado');
                }
            }
        },
    },
    name: "App",
    components: {
        // AnimatedComponent,
        VueScrollSnap,
        FooterComponent,
        FormComplaints,

    },
};
</script>

<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideRight {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.fade-in {
    animation: fadeIn 2s ease-in-out;
}

.slide-right {
    animation: slideRight 2s ease-in-out;
}

.slide-left {
    animation: slideLeft 2s ease-in-out;
}

/* Colour the scroll panels. */
#one {
    background: #1e91d6;
}

#two {
    background: #db5461;
}

#three {
    background: #fc7753;
}

#four {
    background: #ffffff;
}

.item {
    /* Set the minimum height of the items to be the same as the height of the container.*/
    min-height: 50vh;
}

.scroll-snap-container {
    /* Set the container size. */
    /* width: 100vw; */
    height: 100vh;

    /* Center the div in the middle of the screen. */
    /* right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%); */
}

.center-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.multi-line-btn {
    white-space: normal;
    text-align: left;
}

/* Your other styles */
</style>