<template>
  <v-app>
    <v-app-bar v-if="!isHomePage" app image="/images/columnas3.webp" density="compact" rounded>
      <v-app-bar-nav-icon v-if="shouldDisplayNavigationDrawer" variant="text" @click.stop="drawer = true">
      </v-app-bar-nav-icon>
      <v-img src="/images/logo-titulo.png"></v-img>
    </v-app-bar>

    <v-app-bar v-if="isHomePage" app image="/images/columnas3.webp" density="compact" rounded>
      <v-app-bar-nav-icon v-if="shouldDisplayNavigationDrawer" variant="text" @click.stop="drawer = true">
      </v-app-bar-nav-icon>
      <v-img src="/images/logo-titulo.png"></v-img>
      <v-spacer></v-spacer>
      <v-btn><a class="custom-link" href="#contactContainer">Contacto</a></v-btn>
      <v-btn to="/about-us" text>Nosotros</v-btn>
    </v-app-bar>

    <!-- Aquí está la barra lateral de navegación, que debe ocultarse cuando se cargue el formulario de login (LoginForm) y la página de aterrizaje -->
    <v-navigation-drawer v-model="drawer" v-if="shouldDisplayNavigationDrawer" app class="bg-grey-darken-4">
      <v-list dense>
        <!-- Using prepend-icon prop for each v-list-item -->
        <v-list-item v-for="(item, index) in filteredMenuItems" :key="index" link :prepend-icon="item.icon">
          <router-link :to="item.route" class="menu-item">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="shouldDisplayMobileNavbar">
      <v-btn icon @click="toggleDrawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Contenido principal y footer -->
    <v-main class="main-content">
      <router-view></router-view>
      <FooterComponent v-if="showFooter"></FooterComponent>
    </v-main>

    <v-snackbar v-model="showCookieConsent" :timeout="-1" bottom right>
      Este sitio utiliza cookies necesarias para su funcionamiento.
      <v-btn text :to="{ name: 'cookie-policy' }">Política de Cookies</v-btn>
      <v-btn color="blue darken-1" text @click="acceptCookieConsent">OK</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    FooterComponent
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'mainview';
    },
    // Se devuelve al usuario logueado, o un objeto vacío para que no falle en la página de login/aterrizaje, donde no hay un store creado.
    userInfo() {
      return this.$store.state.user || {};
    },
    // Rol de usuario según el store sacado de la función anterior.
    // Los roles son claves foráneas en el usuario, y hacen referencia a la tabla pdd_user_roles
    userRole() {
      return this.userInfo.pddRoleId || {};
    },
    // Esto es necesario para que las pestañas que se verán en la barra lateral correspondan a aquellas donde haya al menos
    // algún permiso según el rol del usuario
    filteredMenuItems() {
      const userRole = this.userRole;
      return this.menuItems.filter(item => item.allowedRoles.includes(userRole));
    },
    // La siguiente función se encarga de comprobar la ruta 
    // para cargar la barra de navegación
    shouldDisplayNavigationDrawer() {
      return this.$route.path === '/home' || this.$route.path === '/empresas'
        || this.$route.path === '/denuncias' || this.$route.path === '/usuarios'
        || this.$route.path === '/resellers';
    },
    showFooter() {
      const staticPaths = ['/login', '/registro', '/home', '/empresas', '/denuncias',
        '/usuarios', '/resellers', '/denunciante', '/no-encontrado'];
      return staticPaths.includes(this.$route.path);
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    acceptCookieConsent() {
      this.setCookie("cookieConsent", "1", 365); // Establece la cookie por 1 año
      this.showCookieConsent = false;
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
  scrollToContactSection() {
    this.$refs.contactContainer.scrollIntoView({ behavior: 'smooth' });
  },
  mounted() {
    if (!this.getCookie("cookieConsent")) {
      this.showCookieConsent = true;
    }
  },
  data() {
    return {
      drawer: null,
      group: null,
      showCookieConsent: false,
      menuItems: [
        { title: 'Inicio', route: '/login', allowedRoles: [1, 2, 3, 7], icon: 'mdi-account' },
        { title: 'Empresas', route: '/empresas', allowedRoles: [1, 7], icon: 'mdi-domain' },
        { title: 'Denuncias', route: '/denuncias', allowedRoles: [2, 3], icon: 'mdi-alert' },
        { title: 'Usuarios', route: '/usuarios', allowedRoles: [1, 7], icon: 'mdi-account-group' },
        { title: 'Resellers', route: '/resellers', allowedRoles: [7], icon: 'mdi-handshake' },
      ],
    };
  },
};
</script>
<style>
@media (max-width: 600px) {
  .responsive-drawer {
    width: 100% !important;
  }
}

.menu-item {
  text-decoration: none;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #2a3b4c;
  /* Cambio de color al pasar el mouse */
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Use min-height instead of height */
}

.v-container {
  flex-grow: 1;
  /* This allows the container to grow as needed */
  flex-shrink: 1;
  /* Prevents the container from shrinking */
  overflow-y: auto;
  /* Adds scrolling to the container */
}

.custom-link {
  text-decoration: none;
  color: #B71C1C;
}
</style>