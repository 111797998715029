<template>
    <v-container class="maincontainer">
        <!-- Botón para abrir el formulario de nueva empresa -->
        <v-btn v-if="userRole === 1 || userRole === 7" color="secondary" variant="tonal" class="mb-3"
            @click="showCreateForm = true">
            <v-icon class="mr-4">mdi-domain</v-icon>
            Crear Nueva Empresa
        </v-btn>
        <v-card>
            <!-- Cuadros de búsqueda -->
            <v-btn color="primary" variant="tonal" @click="showSearchFields = !showSearchFields">
                <v-icon class="mr-4" left>mdi-magnify</v-icon>
                Buscar empresa</v-btn>
            <v-card-text v-if="showSearchFields">
                <v-row justify="left">
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchId" label="Buscar empresa por Id" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchCompany_name" label="Buscar por nombre de empresa" density="compact"
                            variant="solo" single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchNif" label="Buscar por NIF" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchAddress" label="Buscar por dirección" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchEmail" label="Buscar por email" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchPhone" label="Buscar por teléfono" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="searchActivity" label="Buscar por actividad" density="compact" variant="solo"
                            single-line hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-btn @click="resetSearchFields">Limpiar Búsqueda</v-btn>
                    </v-col>

                </v-row>
            </v-card-text>

            <!-- Tabla para listar las empresas -->
            <v-card-title class="text-h5">
                Listado de Empresas
                <v-spacer></v-spacer>
                <span class="text-h6">Compañías activas: {{ companies.length }}</span>
                <span class="text-h6 ml-4">Límite: {{ reseller.maxCompanies || "No especificado" }}</span>
            </v-card-title>
            <v-card-text>
                <v-table>
                    <tbody>
                        <tr v-for="(company, index) in filteredCompanies" :key="index">
                            <td>{{ company.company_name }}</td>
                            <td> <v-btn color="primary" variant="tonal" :href="getCompanyUrl(company)"
                                    target="_blank">Enlace al portal de denuncias</v-btn></td>
                            <!-- Nuevos botones para acciones -->
                            <td>
                                <v-btn small variant="tonal" color="secondary" @click="viewCompany(company)"
                                    class="buttonCrudActions">Ver</v-btn>
                                <v-btn v-if="userRole === 1 || userRole === 7" small variant="tonal" color="primary"
                                    @click="editCompany(company)" class="buttonCrudActions">Editar</v-btn>
                                <v-btn v-if="userRole === 1 || userRole === 7" small variant="tonal" color="error"
                                    @click="deleteCompany(company)" class="buttonCrudActions">Borrar</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>

        <!-- Diálogo para crear nueva empresa -->
        <v-dialog v-model="showCreateForm" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Crear Nueva Empresa
                </v-card-title>
                <v-card-text>
                    <v-form ref="createCompanyForm" v-model="validCompanyForm" lazy-validation>
                        <v-text-field label="Nombre de la Empresa *" v-model="newCompany.company_name"
                            :rules="[rules.required]"></v-text-field>
                        <v-text-field label="NIF" v-model="newCompany.nif"></v-text-field>
                        <v-text-field label="Dirección" v-model="newCompany.address"></v-text-field>
                        <v-text-field label="Correo electrónico" v-model="newCompany.email"></v-text-field>
                        <v-text-field label="Teléfono" v-model="newCompany.phone"></v-text-field>
                        <v-text-field label="Actividad" v-model="newCompany.activity" required></v-text-field>
                        <v-file-input label="Logo de la Empresa" prepend-icon="mdi-image" @change="onFileSelected"
                            accept="image/*"></v-file-input>
                        <v-textarea label="Fragmento de Texto" v-model="newCompany.textSnippet" rows="3"
                            auto-grow></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer> <!-- Esto empuja los botones hacia la derecha -->
                    <v-btn text @click="showCreateForm = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="createCompany" :disabled="!validCompanyForm">Crear</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Diálogo para ver detalles de la empresa -->
        <v-dialog v-model="showViewDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Detalles de la Empresa
                </v-card-title>
                <v-card-text>
                    <p><strong>Nombre:</strong> {{ currentCompany.company_name }}</p>
                    <p><strong>NIF: </strong>{{ currentCompany.nif || 'No facilitado' }}</p>
                    <p><strong>Dirección: </strong>{{ currentCompany.address || 'No facilitado' }}</p>
                    <p><strong>Email: </strong>{{ currentCompany.email || 'No facilitado' }}</p>
                    <p><strong>Teléfono: </strong>{{ currentCompany.phone || 'No facilitado' }}</p>
                    <p><strong>Actividad: </strong>{{ currentCompany.activity || 'No facilitado' }}</p>
                    <p><strong>Texto: </strong>{{ currentCompany.textSnippet || 'No facilitado' }}</p>
                    <p><strong>Fecha de alta: </strong>{{ formatDate(currentCompany.createdAt) }}</p>
                    <p><strong>Última modificación: </strong>{{ formatDate(currentCompany.updatedAt) }}</p>

                    <!-- Agrega aquí más detalles según sea necesario -->
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="showViewDialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Diálogo para editar empresa -->
        <v-dialog v-model="showEditDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Editar Empresa
                </v-card-title>
                <v-card-text>
                    <v-form ref="updateCompanyForm" v-model="valid">
                        <v-text-field label="Nombre de la Empresa" v-model="currentCompany.company_name"></v-text-field>
                        <v-text-field label="NIF" v-model="currentCompany.nif"></v-text-field>
                        <v-text-field label="Dirección" v-model="currentCompany.address"></v-text-field>
                        <v-text-field label="Correo electrónico" v-model="currentCompany.email"></v-text-field>
                        <v-text-field label="Teléfono" v-model="currentCompany.phone"></v-text-field>
                        <v-text-field label="Área de actividad" v-model="currentCompany.activity"></v-text-field>
                        <v-text-field label="Texto" v-model="currentCompany.textSnippet"></v-text-field>
                        <!-- Agrega aquí más campos según sea necesario -->
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="showEditDialog = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="updateCompany">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Diálogo para confirmar el borrado de la empresa -->
        <v-dialog v-model="showDeleteDialog" persistent max-width="300px">
            <v-card>
                <v-card-title class="headline">Confirmación</v-card-title>
                <v-card-text>
                    ¿Estás seguro de que quieres borrar esta empresa?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="showDeleteDialog = false">Cancelar</v-btn>
                    <v-btn color="red darken-1" text @click="confirmDelete">Borrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
            showSearchFields: false,
            searchId: '',
            searchCompany_name: '',
            searchNif: '',
            searchAddress: '',
            searchEmail: '',
            searchPhone: '',
            searchActivity: '',
            showCreateForm: false,
            validCompanyForm: false,
            validUserForm: false,
            newCompany: this.resetNewCompany(),
            newUser: {
                username: '',
                email: '',
                password: '',
                fullName: '',
                dni: '',
                nie: '',
                position: '',
                department: '',
                phone: '',
                companyId: null,
            },
            rules: {
                required: value => !!value || 'Requerido',
                email: v => /.+@.+\..+/.test(v) || 'El correo electrónico debe tener un formato válido',
                password: value => {
                    const errors = [];
                    if (value.length < 8) errors.push("La contraseña debe tener al menos 8 caracteres.");
                    if (!/[A-Z]/.test(value)) errors.push("La contraseña debe contener al menos una letra mayúscula.");
                    if (!/[a-z]/.test(value)) errors.push("La contraseña debe contener al menos una letra minúscula.");
                    if (!/[0-9]/.test(value)) errors.push("La contraseña debe contener al menos un número.");
                    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) errors.push("La contraseña debe contener al menos un carácter especial.");
                    return errors.length ? errors : true;
                },
            },
            valid: true,
            companies: [],
            reseller: [],
            showViewDialog: false,
            showEditDialog: false,
            currentCompany: {},
            showDeleteDialog: false,
            companyToDelete: null,
            snackbar: {
                show: false,
                color: 'success',
                message: ''
            },
        };
    },
    watch: {
        userInfo: {
            immediate: true, // Call the watcher immediately on component mount
            handler(newVal) {
                if (newVal && newVal.resellerId) {
                    this.fetchCompanies();
                }
            }
        }
    },
    mounted() {
        this.fetchResellerData(); // Fetch reseller data when the component is mounted
    },
    computed: {
        userInfo() {
            return this.$store.state.user;
        },
        userRole() {
            return this.$store.state.user.pddRoleId;
        },
        filteredCompanies() {
            return this.companies.filter(company => {
                const matchesCompanyId = company.companyId.toString().includes(this.searchId);
                const matchesCompanyName = company.company_name.toString().toLowerCase().includes(this.searchCompany_name.toLowerCase());
                const matchesCompanyAddress = company.address.toString().toLowerCase().includes(this.searchAddress.toLowerCase());
                const matchesCompanyNif = company.nif.toString().toLowerCase().includes(this.searchNif.toLowerCase());
                const matchesCompanyEmail = company.email.toString().toLowerCase().includes(this.searchEmail.toLowerCase());
                const matchesCompanyPhone = company.phone.toString().toLowerCase().includes(this.searchPhone.toLowerCase());
                const matchesCompanyActivity = company.activity.toString().toLowerCase().includes(this.searchActivity.toLowerCase());
                return matchesCompanyId && matchesCompanyName && matchesCompanyAddress && matchesCompanyNif && matchesCompanyEmail && matchesCompanyPhone && matchesCompanyActivity;
            });
        },
    },
    methods: {
        /**
         * Métodos CRUD
         */

        // Crear compañía
        async createCompany() {
            const isCompanyFormValid = this.$refs.createCompanyForm.validate();
            if (isCompanyFormValid) {
                try {
                    // Paso 1: Crear la compañía
                    const formData = new FormData();
                    this.newCompany.resellerId = this.userInfo.resellerId;

                    for (const key in this.newCompany) {
                        if (key !== 'logoFile') {
                            const value = this.newCompany[key];
                            if (value != null) { // Comprobar null o undefined
                                formData.append(key, value.toString()); // Convertir a string para estar seguros
                            }
                        }
                    }
                    if (this.newCompany.logoFile) {
                        formData.append('logo', this.newCompany.logoFile, this.newCompany.logoFile.name);
                    }
                    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/companies`;
                    const companyResponse = await this.$axios.post(apiUrl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });


                    this.showCreateForm = false;
                    this.newReseller = {};
                    this.newUser = {};
                    this.snackbar.message = 'Compañía creada correctamente';
                    this.snackbar.color = 'success';
                    this.snackbar.show = true;
                    this.showCreateForm = false;
                    this.newCompany = this.resetNewCompany(); // Resetea el formulario
                    await this.fetchCompanies(); // Recargar lista de empresas
                } catch (error) {
                    this.showCreateForm = false;
                    this.snackbar.message = 'Error al crear la empresa: ' + (error.response?.data?.error || error.message);
                    this.snackbar.color = 'error';
                    this.snackbar.show = true;
                    console.error(error);
                }
            }
        },

        // Recuperar compañías por reseller de usuario
        async fetchCompanies() {
            try {
                if (!this.userInfo || !this.userInfo.resellerId) {
                    throw new Error("Información del usuario no disponible");
                }
                const id = this.userInfo.resellerId
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/companies/reseller/${id}`;
                const response = await this.$axios.get(apiUrl);
                this.companies = response.data;
            } catch (error) {
                console.error(error);
            }
        },

        // Actualizar compañía
        async updateCompany() {
            // Lógica para actualizar la empresa
            if (this.$refs.updateCompanyForm.validate()) {
                try {
                    const id = this.currentCompany.companyId;
                    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/companies/${id}`;
                    const response = await this.$axios.put(apiUrl, this.currentCompany);
                    this.showEditDialog = false;
                    await this.fetchCompanies();
                    this.snackbar.message = 'Empresa actualizada correctamente';
                    this.snackbar.color = 'success';
                    this.snackbar.show = true;
                } catch (error) {
                    this.snackbar.message = 'Error al actualizar la empresa';
                    this.snackbar.color = 'error';
                    this.snackbar.show = true;
                    console.error(error);
                }
            }
        },

        // Confirmar borrado
        async confirmDelete() {
            // Lógica para borrar la empresa
            try {
                const id = this.companyToDelete.companyId;
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/companies/${id}`;
                const response = await this.$axios.delete(apiUrl, this.companyToDelete);
                this.showDeleteDialog = false;
                this.companyToDelete = null;
                this.snackbar.message = 'Empresa borrada correctamente';
                this.snackbar.color = 'success';
                this.snackbar.show = true;
                await this.fetchCompanies(); // Recargar lista de denuncias
            } catch (error) {
                this.snackbar.message = 'Error al borrar la empresa';
                this.snackbar.color = 'error';
                this.snackbar.show = true;
                console.error(error);
            }
        },
        async fetchResellerData() {
            try {
                const resellerId = this.userInfo.resellerId;
                if (!resellerId) {
                    this.snackbar.message = 'No existe un identificador de reseller ligado al usuario';
                    this.snackbar.color = 'error';
                    this.snackbar.show = true;
                    throw new Error('Reseller ID not found.');
                }

                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/resellers/${resellerId}`;
                const response = await this.$axios.get(apiUrl);
                this.reseller = response.data;
            } catch (error) {
                this.snackbar.message = 'Error en la recuparación de datos del reseller';
                this.snackbar.color = 'error';
                this.snackbar.show = true;
                console.error('Error fetching reseller data:', error);
            }
        },
        /**
         * Funciones para abrir los diálogos CRUD
         */
        viewCompany(company) {
            this.currentCompany = { ...company };
            this.showViewDialog = true;
        },
        editCompany(company) {
            this.currentCompany = { ...company };
            this.showEditDialog = true;
        },
        deleteCompany(company) {
            this.companyToDelete = company;
            this.showDeleteDialog = true;
        },

        /**
         * Funciones auxiliares
         */

        getCompanyUrl(company) {
            const domain = process.env.VUE_APP_DOMAIN_BASE_URL;
            return `${domain}/${company.hashedCompanyId}`;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Intl.DateTimeFormat('es-ES', options).format(date);
        },
        // Limpiar los campos de búsqueda
        resetSearchFields() {
            this.searchId = '';
            this.searchCompanyName = '';
            this.searchDate = '';
            this.searchCity = '';
            this.searchEmail = '';
        },
        resetNewCompany() {
            return {
                company_name: '',
                nif: '',
                address: '',
                email: '',
                phone: '',
                activity: '',
                resellerId: '',
                logoFile: null,
                textSnippet: '',
            }
        },
        onFileSelected(event) {
            if (event && event.target && event.target.files && event.target.files[0]) {
                this.newCompany.logoFile = event.target.files[0];
                this.snackbar.message = `File selected: ${this.newCompany.logoFile.name}`;
                this.snackbar.color = 'info';
                this.snackbar.show = true;
            }
        },
    },
};
</script>
<style scoped>
.buttonCrudActions {
    margin-left: 10px;
    margin-top: 10px;
}

.maincontainer {
    min-height: 100vh;
}
</style>
  