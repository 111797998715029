<template>
    <v-container fluid class="bg-grey-darken-4 item h-screen" >
        <animated-component>
            <v-row class="d-flex flex-row align-center justify-center">
                <v-col cols="12" md="4" class="order-md-1">
                    <v-card class="align-center" elevation="10">
                        <v-img class="slide-left portrait" src="/images/enterprise.jpg" contain></v-img>
                        <v-card-text>
                            Texto de la primera tarjeta
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" class="order-md-2">
                    <v-card class="card-container" elevation="10">
                        <v-img class="slide-right portrait" src="/images/logo.png" contain></v-img>
                        <v-card-text>
                            Texto de la segunda tarjeta
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </animated-component>
    </v-container>
    <footer-component></footer-component>
</template>
<script>
import AnimatedComponent from '../components/AnimatedComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue'
export default {
    name: "App",
    components: {
        AnimatedComponent,
        FooterComponent,
    },
}
</script>

<style scoped>
.portrait {
    max-height: 400px;
}

</style>