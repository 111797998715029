<template>
    <v-footer padless class="bg-grey-darken-4">
        <v-container>
            <v-row>
                <!-- Primera columna -->
                <v-col cols="12" md="4">
                    <h3>Centro de ayuda</h3>
                    <v-list class="bg-grey-darken-4">
                        <v-list-item v-for="(link, index) in links3" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <a :href="link.href">{{ link.title }}</a>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>

                <!-- Segunda columna -->
                <v-col cols="12" md="4">
                    <h3>Enlaces externos</h3>
                    <v-list class="bg-grey-darken-4">
                        <v-list-item v-for="(link, index) in links2" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <a :href="link.href">{{ link.title }}</a>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>

                <!-- Tercera columna -->
                <v-col cols="12" md="4">
                    <h3>Enlaces</h3>
                    <v-list class="bg-grey-darken-4">
                        <v-list-item v-for="(link, index) in links1" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <a :href="link.href">{{ link.title }}</a>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
  
<script>
export default {
    name: 'FooterComponent',
    data() {
        return {
            links1: [
                { title: 'Política de privacidad', href: '/politica-privacidad' },
                { title: 'Aviso legal', href: '/aviso-legal' },
                { title: 'Política de cookies', href: '/politica-cookies' },

            ],
            links2: [
                { title: 'Normativa BOE', href: 'https://boe.es/boe/dias/2023/02/21/pdfs/BOE-A-2023-4513.pdf' },
            ],
            links3: [
                { title: 'FAQ', href: '/faq'},
            ],
        };
    }
};
</script>
  
<style scoped>
a {
    text-decoration: none;
    color: inherit;
}
</style>  