<template>
    <div>
        <h2>Registro</h2>
        <form @submit.prevent="registerUser">
            <div>
                <label for="username">Nombre de Usuario:</label>
                <input type="text" id="username" v-model="userData.username" required />
            </div>
            <div>
                <label for="email">Correo Electrónico:</label>
                <input type="email" id="email" v-model="userData.email" required />
            </div>
            <div>
                <label for="password">Contraseña:</label>
                <input type="password" id="password" v-model="userData.password" required />
            </div>
            <div>
                <button type="submit">Registrarse</button>
            </div>
        </form>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            userData: {
                username: '',
                email: '',
                password: '',
            },
        };
    },
    methods: {
        async registerUser() {
            try {
                // Llama a la API de autenticación en tu backend
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/registro`;
                const response = await this.$axios.post(apiUrl, this.userData);

                // Usuario autenticado correctamente
                // Redirige al usuario a la página de registro exitoso (cambia '/registro-exitoso' según tu ruta)
                this.$router.push('/registro-exitoso');

                // Realiza cualquier acción adicional que necesites (redirección, actualización de estado, etc.)
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>
  
<style scoped>
div {
    max-width: 400px;
    margin: 0 auto;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    margin-bottom: 8px;
    color: #555;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #45a049;
}
</style>