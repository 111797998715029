<template>
    <v-container fluid class="maincontainer">
        <v-btn color="secondary" variant="tonal" class="mb-3" @click="showCreateForm = true">
            <v-icon class="mr-4">mdi-handshake</v-icon>
            Crear nuevo reseller
        </v-btn>
        <!-- Tabla para listar los resellers -->
        <v-card>
            <v-card-title class="text-h5">
                Listado de resellers
            </v-card-title>
            <v-card-text>
                <v-table>
                    <tbody>
                        <tr v-for="(reseller, index) in resellers" :key="index">
                            <td>{{ reseller.name }}</td>
                            <td>
                                <v-btn small variant="tonal" color="secondary" @click="viewReseller(reseller)"
                                    class="mx-1">Ver</v-btn>
                                <v-btn small variant="tonal" color="primary" @click="editReseller(reseller)"
                                    class="mx-1">Editar</v-btn>
                                <v-btn small variant="tonal" color="error" @click="deleteReseller(reseller)"
                                    class="mx-1">Borrar</v-btn>
                            </td>

                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>

        <!-- Diálogo para crear nuevo reseller (modelos: Reseller y User con pddRoleId = 1) -->
        <v-dialog v-model="showCreateForm" persistent max-width="50vw">
            <v-card>
                <v-card-title>
                    Crear nuevo reseller
                </v-card-title>
                <v-card-text>
                    <v-form ref="createResellerForm" v-model="valid">
                        <v-text-field label="Nombre del reseller" v-model="newReseller.name" :rules="[rules.required]"
                            required></v-text-field>
                        <v-text-field label="NIF" v-model="newReseller.nif"></v-text-field>
                        <v-text-field label="Dirección" v-model="newReseller.address"></v-text-field>
                        <v-text-field label="Email" v-model="newReseller.email"></v-text-field>
                        <v-text-field label="Teléfono" v-model="newReseller.phone"></v-text-field>
                        <v-text-field label="Área de actividad" v-model="newReseller.activity"></v-text-field>
                        <v-file-input label="Logo del reseller" prepend-icon="mdi-image" @change="onFileSelected"
                            accept="image/*"></v-file-input>
                        <v-textarea label="Fragmento de Texto" v-model="newReseller.textSnippet" rows="3"
                            auto-grow></v-textarea>
                        <v-text-field label="Número máximo de empresas que puede crear" v-model="newReseller.maxCompanies"></v-text-field>
                    </v-form>
                </v-card-text>
                <!-- Diálogo para crear nuevo usuario -->
                <v-card-title>Crear nuevo usuario vinculado al reseller</v-card-title>
                <v-card-text>
                    <v-form ref="userForm">
                        <v-text-field label="Nombre de Usuario *" v-model="newUser.username"
                            :rules="[v => !!v || 'El nombre de usuario es requerido']"></v-text-field>
                        <v-text-field label="Correo Electrónico *" v-model="newUser.email"
                            :rules="[v => !!v || 'El correo electrónico es requerido', v => /.+@.+\..+/.test(v) || 'El correo electrónico debe tener un formato válido']">
                        </v-text-field>
                        <v-text-field v-model="newUser.password" label="Contraseña *"
                            :rules="[v => !!v || 'Se requiere establecer una contraseña', passwordRule]"></v-text-field>
                        <v-alert color="error" v-if="passwordMessage">
                            <v-layout wrap>
                                <div class="error-message">{{ passwordMessage }}</div>
                            </v-layout>
                        </v-alert>
                        <v-text-field label="DNI" v-model="newUser.dni"></v-text-field>
                        <v-text-field label="NIE" v-model="newUser.nie"></v-text-field>
                        <v-text-field label="Departamento" v-model="newUser.department"></v-text-field>
                        <v-text-field label="Cargo" v-model="newUser.position"></v-text-field>
                        <v-text-field label="Teléfono" v-model="newUser.phone"></v-text-field>
                        <!-- Agregar más campos si es necesario -->
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer> <!-- Esto empuja los botones hacia la derecha -->
                    <v-btn text @click="showCreateForm = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="createResellerAndUser">Crear</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Diálogo para ver detalles del reseller -->
        <v-dialog v-model="showViewDialog" persistent max-width="50vw">
            <v-card>
                <v-card-title>
                    Detalles del reseller
                </v-card-title>
                <v-card-text>
                    <p><strong>Nombre: </strong> {{ currentReseller.name || 'No se ha introducido' }}</p>
                    <p><strong>NIF: </strong>{{ currentReseller.nif || 'No se ha introducido' }}</p>
                    <p><strong>Dirección: </strong>{{ currentReseller.address || 'No se ha introducido' }}</p>
                    <p><strong>Email: </strong>{{ currentReseller.email || 'No se ha introducido' }}</p>
                    <p><strong>Teléfono: </strong>{{ currentReseller.phone || 'No se ha introducido' }}</p>
                    <p><strong>Área de actividad: </strong>{{ currentReseller.activity || 'No se ha introducido' }}</p>
                    <p><strong>Fecha de alta: </strong>{{ currentReseller.createdAt ? formatDate(currentReseller.createdAt)
                        : 'No introducido' }}</p>
                    <!-- <p><strong>Logo: </strong><v-img :src="currentReseller.logoImagePath"></v-img></p>
                    <p><strong>Texto: </strong>{{ currentReseller.textSnippet || 'No se ha introducido' }} </p> -->
                    <p><strong>Fecha de última modificación: </strong>{{ currentReseller.updatedAt ?
                        formatDate(currentReseller.updatedAt) : 'No introducido' }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="showViewDialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Diálogo para editar reseller -->
        <v-dialog v-model="showEditDialog" persistent max-width="50vw">
            <v-card>
                <v-card-title>
                    Editar reseller
                </v-card-title>
                <v-card-text>
                    <v-form ref="updateResellerForm" v-model="valid">
                        <v-text-field label="Nombre del reseller" v-model="currentReseller.name"></v-text-field>
                        <v-text-field label="NIF" v-model="currentReseller.nif"></v-text-field>
                        <v-text-field label="Dirección" v-model="currentReseller.address"></v-text-field>
                        <v-text-field label="Email" v-model="currentReseller.email"></v-text-field>
                        <v-text-field label="Teléfono" v-model="currentReseller.phone"></v-text-field>
                        <v-text-field label="Área de actividad" v-model="currentReseller.activity"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="showEditDialog = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="updateReseller">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Diálogo para confirmar el borrado de la empresa -->
        <v-dialog v-model="showDeleteDialog" persistent max-width="50vw">
            <v-card>
                <v-card-title class="headline">Confirmación</v-card-title>
                <v-card-text>¿Está seguro de querer eliminar el reseller?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color=" green-darken-1" text @click="showDeleteDialog = false">Cancelar</v-btn>
                    <v-btn color="red-darken-1" text @click="confirmDelete">Borrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            resellers: [],
            currentReseller: {},
            showCreateForm: false,
            showViewDialog: false,
            showEditDialog: false,
            showDeleteDialog: false,
            resellerToDelete: null,
            newReseller: {
                name: '',
                nif: '',
                address: '',
                email: '',
                phone: '',
                activity: '',
                logoFile: null,
                textSnippet: '',
                maxCompanies: null,
            },
            newUser: {
                username: '',
                email: '',
                password: '',
                dni: '',
                nie: '',
                position: '',
                department: '',
                phone: '',
                companyId: null,
            },
            rules: {
                required: value => !!value || 'Requerido',
            },
            valid: true,
            snackbar: {
                show: false,
                color: 'success',
                message: ''
            },
        };
    },
    mounted() {
        this.fetchResellers();
    },
    computed: {
        userInfo() {
            return this.$store.state.user;
        },
        showResellerField() {
            return this.userInfo && this.userInfo.pddRoleId === 7;
        },
        // Necesario para validar el formulario
        isFormValid() {
            return (
                this.newUser.username &&
                this.newUser.email &&
                this.newUser.password &&
                this.newUser.pddRoleId !== 'Seleccione un rol'
            );
        },
        passwordRule() {
            return (value) => {
                // Expresiones regulares para las validaciones
                const lengthRegex = /.{10,}/;
                const uppercaseRegex = /[A-Z]/;
                const lowercaseRegex = /[a-z]/;
                const digitRegex = /[0-9]/;
                const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
                value = value.trim();

                // Comprobación de requisitos y mensajes de error
                let message = "";

                if (!lengthRegex.test(value)) {
                    message += "La contraseña debe tener al menos 10 caracteres de largo\n";
                }

                if (!uppercaseRegex.test(value)) {
                    message += "Debe incluir al menos una letra mayúscula\n";
                }

                if (!lowercaseRegex.test(value)) {
                    message += "Debe incluir al menos una letra minúscula\n";
                }

                if (!digitRegex.test(value)) {
                    message += "Debe incluir al menos un número\n";
                }

                if (!specialCharRegex.test(value)) {
                    message +=
                        "Debe incluir al menos un carácter no alfanumérico (por ejemplo: $%&)<br>";
                }

                // Actualizar passwordMessage según resultado de las validaciones
                this.passwordMessage = message;

                // Devuelve verdadero si la contraseña cumple todos los requisitos, o falso si no
                return message === "";
            };
        },
    },
    methods: {
        /**
        * Funciones auxiliares
        */
        // Dar formato a las fechas
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Intl.DateTimeFormat('es-ES', options).format(date);
        },
        // Marcar archivo (logo) seleccionado
        onFileSelected(event) {
            if (event && event.target && event.target.files && event.target.files[0]) {
                this.newReseller.logoFile = event.target.files[0];
                this.snackbar.message = `File selected: ${this.newReseller.logoFile.name}`;
                this.snackbar.color = 'info';
                this.snackbar.show = true;
            }
        },
        /**
         * Funciones CRUD
         */
        // Crear un reseller y un usuario ligado administrador de dicho reseller
        async createResellerAndUser() {

            if (this.$refs.createResellerForm.validate()) {
                try {
                    // Paso 1: Crear el Reseller
                    const formData = new FormData();
                    for (const key in this.newReseller) {
                        if (key !== 'logoFile') {
                            formData.append(key, this.newReseller[key] != null ? this.newReseller[key].toString() : '');
                        }
                    }
                    if (this.newReseller.logoFile) {
                        formData.append('logo', this.newReseller.logoFile, this.newReseller.logoFile.name);
                    }
                    const resellerId = this.$store.state.user.resellerId;
                    formData.append('userResellerId', resellerId);
                    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/resellers`;
                    const resellerResponse = await this.$axios.post(apiUrl, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });

                    // Paso 2: Crear User con el resellerId retornado
                    const userPayload = {
                        ...this.newUser,
                        resellerId: resellerResponse.data.resellerId,
                        pddRoleId: 1 // "Harcdoded", el usuario administrador (mananger) tiene el pddRoleId === 1
                    };
                    const userApiUrl = `${process.env.VUE_APP_API_BASE_URL}/registro`;
                    await this.$axios.post(userApiUrl, userPayload, {
                        headers: { 'Content-Type': 'application/json' },
                    });

                    this.showCreateForm = false;
                    this.newReseller = {};
                    this.newUser = {};
                    this.snackbar.message = 'Reseller y usuario creados correctamente';
                    this.snackbar.color = 'success';
                    this.snackbar.show = true;
                    await this.fetchResellers();
                } catch (error) {
                    this.snackbar.message = 'Error al crear el reseller o usuario';
                    this.snackbar.color = 'error';
                    this.snackbar.show = true;
                    console.error(error);
                }
            }
        },
        // Recuperar resellers
        async fetchResellers() {
            try {
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/resellers`;
                const response = await this.$axios.get(apiUrl);
                this.resellers = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        // Actualizar resellers
        async updateReseller() {
            if (this.$refs.updateResellerForm.validate()) {
                try {
                    const id = this.currentReseller.resellerId;
                    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/resellers/${id}`
                    const response = await this.$axios.put(apiUrl, this.currentReseller);
                    this.showEditDialog = false;
                    await this.fetchResellers();
                    this.snackbar.message = 'Reseller actualizado correctamente';
                    this.snackbar.color = 'success';
                    this.snackbar.show = true;
                } catch (error) {
                    this.snackbar.message = 'Error al actualizar el reseller';
                    this.snackbar.color = 'error';
                    this.snackbar.show = true;
                    console.error(error);
                }
            }
        },
        // Borrar resellers
        async confirmDelete() {
            try {
                const id = this.resellerToDelete.resellerId;
                const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/resellers/${id}`;
                const response = await this.$axios.delete(apiUrl, this.resellerToDelete);
                this.showDeleteDialog = false;
                this.resellerToDelete = null;
                this.snackbar.message = 'Reseller borrado con éxito';
                this.snackbar.color = 'success';
                this.snackbar.show = true;
                await this.fetchResellers();
            } catch (error) {
                this.snackbar.message = 'Error al borrar el reseller';
                this.snackbar.color = 'error';
                this.snackbar.show = true;
                console.error(error);
            }
        },

        viewReseller(reseller) {
            this.currentReseller = { ...reseller };
            this.showViewDialog = true;
        },
        editReseller(reseller) {
            this.currentReseller = { ...reseller };
            this.showEditDialog = true;
        },
        deleteReseller(reseller) {
            this.resellerToDelete = reseller;
            this.showDeleteDialog = true;
        },
    },
}
</script>

<style scoped>
.maincontainer {
    min-height: 100vh;
}
</style>