<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Reiniciar contraseña</h1>
        <p>Introduzca su nueva contraseña a continuación</p>
        <v-form ref="form" @submit.prevent="resetPassword">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="password1"
                label="Nueva contraseña"
                type="password"
                :rules="passwordRules"
                required
                :error-messages="getPassword1Errors"
                @input="validateForm"
              ></v-text-field>
              <small class="form-text text-muted"
                >La contraseña debe tener al menos 8 caracteres, una letra
                minúscula, una letra mayúscula y un número.</small
              >
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password2"
                label="Confirmar la nueva contraseña"
                type="password"
                :rules="[passwordMatchRule]"
                required
                :error-messages="getPassword2Errors"
                @input="validateForm"
              ></v-text-field>
              <small class="form-text text-muted"
                >Ambas contraseñas deben coincidir.</small
              >
            </v-col>
          </v-row>
          <input type="hidden" id="emailRp" name="email" :value="email" />
          <input type="hidden" id="tokenRp" name="token" :value="token" />
          <v-btn
            id="rpButton"
            type="submit"
            color="success"
            :disabled="!isFormValid"
          >
            Regenerar contraseña
          </v-btn>
        </v-form>
        <v-alert v-if="message" :type="messageType" class="reset-message">
          {{ message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      password1: "",
      password2: "",
      email: "",
      token: "",
      message: "",
      messageType: "",
      passwordRules: [
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe tener al menos 8 caracteres",
        (v) =>
          /[a-z]/.test(v) ||
          "La contraseña debe contener al menos una letra minúscula",
        (v) =>
          /[A-Z]/.test(v) ||
          "La contraseña debe contener al menos una letra mayúscula",
        (v) => /\d/.test(v) || "La contraseña debe contener al menos un número",
      ],
    };
  },
  computed: {
    isFormValid() {
      return (
        this.password1 === this.password2 && this.password1 && this.password2
      );
    },
    getPassword1Errors() {
      return this.passwordRules
        .filter((rule) => !rule(this.password1))
        .map((rule) => rule(this.password1));
    },
    getPassword2Errors() {
      return this.password1 !== this.password2
        ? ["Las contraseñas no coinciden"]
        : [];
    },
  },
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
    async resetPassword() {
      if (!this.isFormValid) {
        return;
      }

      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/reset-password`;
      try {
        const response = await this.$axios.post(apiUrl, {
          password: this.password1,
          email: this.email,
          token: this.token,
        });
        if (response.statusText === "OK") {
          this.message = response.data.message;
          this.messageType = "success";
          // Additional logic if needed
        } else {
          this.message = response.data.message;
          this.messageType = "error";
        }
      } catch (error) {
        this.message = "Ha ocurrido un error. Por favor, inténtelo más tarde.";
        this.messageType = "error";
        // console.error(error);
      }
    },
  },
  mounted() {
    this.email = this.$route.query.email; // Assign the email value from the route query parameters
    this.token = this.$route.query.token; // Assign the token value from the route query parameters
  },
};
</script>
