<template>
    <v-container>
        <v-app>
            <v-main>
                <!-- Tarjeta para la introducción -->
                <v-card class="my-3">
                    <h3 class="headline">
                        Política de Privacidad
                    </h3>
                    <v-card-text>
                        De conformidad con lo dispuesto en el Reglamento General de Protección de Datos RGPD UE 679/2016
                        y la Ley Orgánica 3/2018 de 5 de Diciembre, de Protección de Datos Personales y Garantía de los
                        Derechos Digitales, se le facilita la siguiente información del tratamiento de sus datos de
                        carácter personal.
                    </v-card-text>
                </v-card>

                <!-- Tarjeta para los corresponsables del tratamiento -->
                <v-card class="my-3">
                    <h3>
                        ¿Quién son los corresponsables del tratamiento de sus datos personales?
                    </h3>
                    <v-card-text>
                        Corresponsable del tratamiento: J. AGUILO SISTEMAS Y REDES S.L., CIF: B07934318, Domicilio:
                        CAMINO NOU 7, BAJOS 2, 07009 PALMA DE MALLORCA, BALEARES, Dirección de correo electrónico:
                        dpo@syr.es, Teléfono: 971249957, Inscrito en el Registro Mercantil Islas Baleares al Tomo 1627,
                        Folio 123, Hoja PM 31250, Sección 8.
                        <br><br>
                        Corresponsable del tratamiento: PROTOCOLOS DE SEGURIDAD INFORMATICA PROTECMIR S.L., NIF/CIF:
                        B87193207, Dirección: PASEO DE LA CASTELLANA, Nº 40, 8ª PLANTA, CP: 28046, MADRID, Teléfono
                        971918848, Email: protecmirlegal@protecmir.com. DELEGADO DE PROTECCIÓN DE DATOS: CONTACTO:
                        http://www.protecmir.com, EMAIL: protecmirlegal@protecmir.com.
                    </v-card-text>
                </v-card>

                <!-- Tarjeta para "¿Qué datos tratamos?" -->
                <v-card class="my-3">
                    <h3>
                        ¿Qué datos tratamos?
                    </h3>
                    <v-card-text>
                        Tratamos los datos personales facilitados por usted a través de la cumplimentación de los
                        formularios habilitados a tal efecto en este portal de denuncias, los datos personales derivados
                        de la prestación o contratación de nuestros servicios o productos y/o los datos personales
                        derivados de la relación comercial que usted mantiene con nosotros. Además, tratamos los datos
                        personales generados en el contexto de su actividad de nuestro portal de denuncias.
                        <br><br>
                        Es importante para nosotros mantener el registro de sus datos personales actualizado. Usted
                        tiene la obligación de mantenernos informados de cualquier cambio o error en sus datos
                        personales tan pronto como sea posible poniéndose en contacto con nosotros en el correo
                        electrónico: dpo@syr.es y protecmirlegal@protecmir.com.
                    </v-card-text>
                </v-card>

                <!-- Tarjeta para "¿Con qué finalidad tratamos sus datos de carácter personal?" -->
                <v-card class="my-3">
                    <h3>
                        ¿Con qué finalidad tratamos sus datos de carácter personal?
                    </h3>
                    <v-card-text>
                        Finalidad: J. AGUILO SISTEMAS Y REDES S.L y PROTOCOLOS DE SEGURIDAD INFORMATICA PROTECMIR S.L.
                        podrán tratar sus datos personales para las siguientes finalidades:
                        <br /><br>
                        <ul>
                            <li>Gestión y mantenimiento de los servicios proporcionados a través del portal de
                                denuncias.</li>
                            <li>Gestión y atención de las consultas y solicitudes de información realizadas por los
                                usuarios a través del formulario de contacto. La dirección de correo electrónico y los
                                datos de carácter personal que usted nos proporciona a través del formulario de contacto
                                del portal de denuncias serán utilizados exclusivamente para atender las consultas que
                                nos plantee por este medio.</li>
                            <li>Cumplimiento de las obligaciones legales aplicables.</li>
                            <li>Envío de comunicaciones comerciales y boletines informativos, así como publicidad de
                                nuestros servicios y productos.</li>
                            <li>Gestión de la contratación de nuestros servicios y/o productos.</li>
                            <li>Gestión de las solicitudes de información o solicitudes de presupuestos de nuestros
                                servicios o productos.</li>
                            <li>Registro en el área de usuarios.</li>
                            <li>Tratamiento de las denuncias presentadas a través del portal de denuncias.</li>
                        </ul>
                        <p> Conservamos sus datos de carácter personal en nuestros sistemas y archivos el tiempo
                            necesario para llevar a cabo los fines del tratamiento, y para cumplir con lo dispuesto en
                            la legislación aplicable. Se conservarán sus datos personales mientras exista una relación
                            contractual y/o comercial con usted, o mientras usted no ejerza su derecho de supresión y/o
                            limitación del tratamiento de sus datos. El periodo de conservación de los datos personales
                            variará en función de los fines del tratamiento y con carácter general:</p>
                        <ul>
                            <li> Los datos personales que nos proporciona en los supuestos de contratación de nuestros
                                servicios
                                o productos serán conservados durante la relación contractual y, una vez finalizada la
                                misma,
                                durante el plazo de prescripción de las acciones legales que se puedan derivar de la
                                misma.</li>
                            <li> Los datos personales que nos proporciona para gestionar solicitudes de información o
                                consultas
                                a través del formulario de contacto se conservarán mientras usted no solicite su
                                supresión o
                                cancelación.</li>
                            <li> Los datos personales que nos proporciona para suscribirse a nuestro Newsletter o
                                boletines
                                informativos se conservarán mientras usted no solicite su supresión, oposición y/o
                                limitación.</li>
                            <li> Los datos personales que nos proporciona a través del portal de denuncias se
                                conservarán
                                durante un plazo no superior a 10 años.</li>
                        </ul>
                        <br>
                        <p>Sus datos de carácter personal serán conservados mientras sean útiles para las
                            finalidades
                            indicadas, y, en cualquier caso, durante los plazos legales y el tiempo necesario para
                            atender a
                            posibles responsabilidades nacidas del tratamiento.</p>
                    </v-card-text>
                </v-card>

                <!-- Tarjeta para "Seguridad de los datos" -->
                <v-card class="my-3">
                    <h3>
                        Seguridad de los datos
                    </h3>
                    <v-card-text>
                        Disponemos de medidas de seguridad técnicas y organizativas adecuadas para proteger sus datos
                        personales contra el tratamiento no autorizado o ilícito y contra su pérdida, destrucción o daño
                        accidental, asegurando la integridad y confidencialidad de los mismos. Las medidas de seguridad
                        técnicas y organizativas implantadas permiten: garantizar la confidencialidad, integridad,
                        disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento; restaurar
                        la disponibilidad y el acceso a los datos de carácter personal de forma rápida en caso de
                        incidente físico o técnico; y verificar, evaluar y valorar de forma regular la eficacia de las
                        medidas técnicas y organizativas implantadas para garantizar la seguridad del tratamiento.
                    </v-card-text>
                    <v-card-text>Estas medidas de seguridad técnicas y organizativas han sido diseñadas teniendo en
                        cuenta nuestra infraestructura de TI, el estado de la técnica de acuerdo con los estándares y
                        prácticas actuales, el coste de la aplicación y la naturaleza, ámbito, contexto y fines del
                        tratamiento, así como los riesgos de diversa probabilidad y gravedad que entraña el tratamiento
                        para sus datos personales.</v-card-text>
                </v-card>

                <!-- Tarjeta para "¿Cuál es la legitimación para el tratamiento de sus datos?" -->
                <v-card class="my-3">
                    <h3>
                        ¿Cuál es la legitimación para el tratamiento de sus datos?
                    </h3>
                    <v-card-text>
                        Legitimación: La legitimación para tratar sus datos de carácter personal está basada en:
                        <br><br>
                        <ul>
                            <li>La ejecución y mantenimiento de una relación contractual y comercial con usted, como,
                                por ejemplo la contratación de productos y servicios de la entidad, gestión y solicitud
                                de presupuestos de los productos y/o servicios de la entidad, todo ello de conformidad
                                con lo dispuesto en el artículo 6.1.B del RGPD (UE) 679/2016 y la Ley Orgánica 3/2018,
                                de 5 de diciembre (LOPDPGDD).</li>
                            <li>Su consentimiento expreso para una o varias finalidades, como por ejemplo el envío de
                                comunicaciones publicitarias o boletines informativos propios o de terceros, todo ello
                                de conformidad con lo dispuesto el artículo 6.1.A del RGPD (UE) 679/2016 y la Ley
                                Orgánica 3/2018, de 5 de diciembre (LOPDPGDD).</li>
                            <li>El cumplimiento de distintas obligaciones legales, todo ello de conformidad con lo
                                dispuesto en el artículo 6.1.C del RGPD (UE) 679/2016 y la Ley Orgánica 3/2018, de 5 de
                                diciembre (LOPDPGDD).</li>
                            <li>La satisfacción de intereses legítimos perseguidos por el responsable del tratamiento o
                                por un tercero, por ejemplo, por motivos de seguridad, para mejorar nuestros servicios y
                                o para gestionar sus solicitudes o consultas.</li>
                        </ul>
                        <p>Durante el proceso de recogida de datos, y en cada lugar del portal de denuncias en el que se
                            soliciten datos de carácter personal, el usuario será informado, ya sea mediante un
                            hipervínculo, ya sea mediante la inclusión de las menciones oportunas en el propio
                            formulario,
                            del carácter obligatorio o no de la recogida de sus datos de carácter personal.</p>
                        <p>Los datos personales solicitados en los formularios del portal de denuncias son, con carácter
                            general, obligatorios (salvo que en el campo requerido se especifique lo contrario) para
                            cumplir
                            con las finalidades establecidas. Por lo tanto, si no se facilitan los datos personales
                            solicitados, o no se facilitan correctamente, no podrán atenderse las mismas. No obstante,
                            si
                            usted es denunciante no se encuentra obligado a facilitar sus datos de carácter personal ya
                            que
                            se admiten las denuncias anónimas.</p>
                        <p>Existe la obligación de facilitar sus datos de carácter personal en los supuestos de
                            contratación de un servicio o producto, y/o en aquellos supuestos de solicitud de
                            presupuestos u
                            ofertas.</p>
                        <p>El envío de comunicaciones publicitarias, newsletter o boletines informativos de nuestros
                            productos y servicios está basado en el consentimiento que se le solicita, sin que en ningún
                            caso la retirada de este consentimiento condicione la relación contractual o comercial que
                            usted
                            mantiene con nosotros.</p>
                        <p>Si usted nos ha autorizado a enviar publicidad de nuestros servicios y productos, sus datos
                            de
                            carácter personal pueden ser utilizados para gestionar el envío de ofertas publicitarias y
                            boletines informativos a través de medios electrónicos. En estos casos resulta de aplicación
                            lo
                            dispuesto en los art. 20 y 21 de la ley 34/2002, de 11 de julio, de servicios de la sociedad
                            de
                            la información y de comercio electrónico, respecto al uso y tratamiento de sus datos de
                            carácter
                            personal con el fin de gestionar el envío de publicidad por medios electrónicos.</p>
                        <p>En caso de que haya marcado la opción de recibir publicidad, o si se ha suscrito a nuestro
                            newsletter o boletín informativo, usted puede cancelar esta opción en cualquier momento.</p>

                    </v-card-text>
                </v-card>


                <!-- Tarjeta para "¿A qué destinatarios se comunicarán sus datos?" -->
                <v-card class="my-3">
                    <h3>
                        ¿A qué destinatarios se comunicarán sus datos?
                    </h3>
                    <v-card-text>
                        Destinatarios: Con carácter general, sus datos de carácter personal no se comunicarán a ningún
                        tercero ajeno a la entidad, salvo obligación legal. No obstante, le informamos de que terceros
                        proveedores pueden tener acceso a sus datos personales en calidad de encargados del tratamiento,
                        en el marco de la prestación de un servicio para la entidad responsable del tratamiento. Le
                        informamos de que puede solicitar la relación completa de los destinatarios que podrán recibir
                        sus datos personales como encargados de tratamiento. Además de lo anterior, la entidad podrá
                        realizar cesiones o comunicaciones de datos personales para atender sus obligaciones ante las
                        Administraciones Públicas en los casos que así se requiera, de acuerdo con la legislación
                        vigente.
                    </v-card-text>
                    <v-card-text>No hay previsión de transferencias internacionales de datos.</v-card-text>
                </v-card>

                <!-- Tarjeta para "¿Qué derechos tiene [usted] en cuanto al tratamiento de sus datos personales?" -->
                <v-card class="my-3">
                    <h3>
                        ¿Qué derechos tiene [usted] en cuanto al tratamiento de sus datos personales?
                    </h3>
                    <v-card-text>
                        Derechos: Usted tiene derecho a obtener el acceso a sus datos personales, así como a solicitar
                        la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre
                        otros motivos, los datos que ya no sean necesarios para los fines que fueron recogidos. En
                        determinadas circunstancias, usted puede solicitar la limitación del tratamiento de sus datos,
                        en cuyo caso únicamente los conservaremos para el ejercicio o defensa de reclamaciones.
                        Asimismo, y por motivos relacionados con su situación particular, usted puede oponerse al
                        tratamiento de sus datos, en cuyo caso su información personal dejará de tratarse para aquellas
                        finalidades respecto de las que haya manifestado su oposición. Cuando sea técnicamente posible,
                        usted puede solicitar la portabilidad de sus datos a otro responsable del tratamiento. Para
                        ejercitar estos derechos, de conformidad con la legislación vigente, usted puede dirigirse por
                        correo postal, adjuntando copia de un documento acreditativo de su identidad (DNI), a los emails
                        dpo@syr.es o protecmirlegal@protecmir.com. Usted tiene derecho a presentar una Reclamación ante
                        la Autoridad de Control: Agencia Española de Protección Datos (www.agpd.es). Procedencia de los
                        Datos de Carácter Personal: el propio interesado.
                    </v-card-text>
                    <v-card-text>
                        Usted acepta expresamente la inclusión de los datos de carácter personal recabados durante la
                        navegación en el portal de denuncias y/o proporcionados mediante la cumplimentación de cualquier
                        formulario, así como los derivados de una posible relación comercial, en los ficheros
                        automatizados de datos de carácter personal de la entidad. La entidad garantiza la
                        confidencialidad de los datos de carácter personal de los usuarios y revelará a las autoridades
                        públicas competentes los datos personales y cualquier otra información que esté en su poder o
                        sea accesible a través de sus sistemas y sea requerida, de conformidad con las disposiciones
                        legales y reglamentarias aplicables al caso.
                    </v-card-text>
                    <v-card-text>
                        La entidad garantiza la confidencialidad de los datos de carácter personal de los usuarios. No
                        obstante, la entidad revelará a las autoridades públicas competentes los datos personales y
                        cualquier otra información que esté en su poder o sea accesible a través de sus sistemas y sea
                        requerida, de conformidad con las disposiciones legales y reglamentarias aplicables al caso. Los
                        datos de carácter personal pueden ser conservados en los ficheros titularidad de J. AGUILO
                        SISTEMAS
                        Y REDES S.L y PROTOCOLOS DE SEGURIDAD INFORMATICA PROTECMIR S.L. incluso una vez finalizadas las
                        relaciones comerciales formalizadas a través del portal de denuncias, exclusivamente para los
                        fines
                        indicados anteriormente y, en todo caso, durante los plazos legalmente establecidos, a
                        disposición
                        de autoridades administrativas o judiciales.
                    </v-card-text>
                </v-card>
            </v-main>
        </v-app>

    </v-container>
    <FooterComponent class="item"></FooterComponent>
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
export default {
    name: "App",
    components: {
        FooterComponent,
    },
}
</script>