<!-- eslint-disable vue/no-multiple-template-root -->

<template>
  <v-app>
    <v-app-bar app density="compact" class="sticky-app-bar">
      <v-spacer></v-spacer>
      <v-btn @click="logout" text>Cerrar sesión</v-btn>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">Cerrar sesión</v-card-title>
          <v-card-text>¿Estás seguro de que quieres cerrar sesión?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="confirmLogout">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-row class="mt-5"></v-row>
            <v-row>
              <v-col cols="12">
                <v-text v-if="$store.state.user" class="text-h4">Bienvenid@, {{ $store.state.user.username
                  }}</v-text>
              </v-col>
              <v-col cols="12">
                <v-text v-if="$store.state.user" class="text-h5">Su email es {{ $store.state.user.email
                  }}</v-text>
              </v-col>
              <v-col cols="12" class="body-1">
                Aquí puede consultar las denuncias que haya registrado, así como añadir nueva documentación. También
                puede
                compartir mensajes con la persona designada como mediadora entre usted y la empresa denunciada.
              </v-col>
            </v-row>

            <v-row class="mb-5"></v-row>

            <!-- Revisar las denuncias para mostrar -->
            <div v-if="userComplaints.length > 0">
              <v-card v-for="(complaint, index) in userComplaints" :key="complaint.complaintId" class="mb-4"
                elevation="12">
                <!-- Detalles de la denuncia en su propia tarjeta -->
                <v-card-title class="headline">
                  Denuncia nº {{ index + 1 }}
                </v-card-title>
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><strong>Email:</strong> {{ complaint.complainantEmail }}</v-list-item-title>
                      <v-list-item-subtitle class="pt-2"><strong>Fecha de registro de la denuncia:</strong> {{
        formatDate(complaint.createdAt) }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="complaint.resolutionText"
                        style="white-space: pre-wrap;"><strong>Resolución:</strong> {{ complaint.resolutionText
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>

                <v-row>
                  <v-col cols="12">
                    <!-- Componente para los mensajes -->
                    <MessagingComponent :complaint="complaint" :user-info="$store.state.user" />
                  </v-col>

                  <v-col cols="6">
                    <!-- Card para el botón de subir archivos -->
                    <v-card class="mt-4 px-2 py-4" rounded="lg" outlined>
                      <v-card-title class="headline">Subir Archivos</v-card-title>
                      <v-btn @click="showFileDialog(index)" color="primary">
                        <v-icon left class="mr-4">mdi-upload</v-icon>
                        Seleccionar archivos
                      </v-btn>

                      <!-- Diálogo con v-file-input específico de esta denuncia -->
                      <v-dialog v-model="filesDialogs[index]" persistent max-width="600px">
                        <v-card>
                          <v-card-title>Subir archivos</v-card-title>
                          <v-card-text>
                            <v-file-input v-model="files[index]" label="Selecciona archivos" multiple
                              accept="image/png, image/jpeg, application/pdf, video/x-matroska"></v-file-input>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn color="blue darken-1" text
                              @click="uploadFiles(complaint.complaintId, index)">Subir</v-btn>
                            <v-btn color="grey" text @click="closeFileDialog(index)">Cancelar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <v-card v-else>
              <v-alert border="left" colored-border color="red" elevation="2" prominent>
                No se ha encontrado ninguna denuncia.
              </v-alert>
            </v-card>

          </v-col>
          <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">Cerrar</v-btn>
          </v-snackbar>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MessagingComponent from '@/components/MessagingComponent.vue';
export default {
  components: {
    MessagingComponent
  },
  actions: {
    setUserAndPersist(context, userData) {
      context.commit('SET_USER', userData);
    }
  },
  mutations: {
    SET_USER(state, userData) {
      state.user = userData;
    }
  },
  created() {
    this.fetchUserComplaintsAndResolutions();
  },
  data() {
    return {
      dialog: false,
      filesDialogs: {},
      userComplaints: [],
      complaints: [],
      files: {},
      snackbar: {
        show: false,
        color: '',
        message: '',
      },
    }
  },
  methods: {
    async fetchUserComplaintsAndResolutions() {
      try {
        const requestBody = {
          email: this.$store.state.user.email
        };

        const response = await this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/complaints/email`, requestBody);
        const userComplaints = response.data;
        for (let complaint of userComplaints) {
          if (complaint.status === 'Proceso cerrado') {
            const resolutionResponse = await this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/resolutions/complaint/${complaint.complaintId}`);
            complaint.resolutionText = resolutionResponse.data.resolutionText;
          }
        }

        this.userComplaints = userComplaints;
      } catch (error) {
        console.error('Error fetching user complaints and resolutions:', error);
      }
    },

    showFileDialog(index) {
      this.filesDialogs[index] = true; // Abre el diálogo de subida de archivos para la denuncia específica
    },
    closeFileDialog(index) {
      this.filesDialogs[index] = false; // Cierra el diálogo de subida de archivos para la denuncia específica
    },

    // Subir archivos
    async uploadFiles(complaintId, index) {
      const selectedFiles = this.files[index];
      if (!selectedFiles || selectedFiles.length === 0) return;

      let formData = new FormData();
      selectedFiles.forEach(file => {
        formData.append('files', file);
      });

      formData.append('complaintId', complaintId); // Añade el complaintId al formData
      try {
        const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/files`;
        await this.$axios.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.closeFileDialog(index); // Cierra el diálogo después de la subida
        this.snackbar = {
          show: true,
          color: 'success',
          message: 'Los archivos se han subido correctamente.',
        };
      } catch (error) {
        console.error('Error al subir archivos:', error);
        this.snackbar = {
          show: true,
          color: 'error',
          message: 'Error al subir archivos. Por favor, inténtalo de nuevo.',
        };
      }
    },

    // Función para dar formato a la fecha de los hechos de la denuncia
    formatDate(complaintDate) {
      const date = new Date(complaintDate);
      const day = date.getDate();
      const month = date.toLocaleString('es-ES', { month: 'long' });
      const year = date.getFullYear();
      const formattedDate = `${day} de ${month} de ${year}`;
      return formattedDate;
    },
    confirmLogout() {
      try {
        this.$store.dispatch('logout')
          .then(() => {
            // Redireccionar a la página de login tras salir
            this.dialog = false;
            this.$router.push('/login');
          })
          .catch(error => {
            console.error('Error on logout:', error);
          });
      } catch (error) {
        console.error(error);
      }
    },
    logout() {
      this.dialog = true;
    },
  }
};
</script>

<style scoped>
.sticky-app-bar {
  position: fixed !important;
  margin-top: 48px !important;
  z-index: 100;
  /* Asegúrate de que la barra esté por encima del contenido */
}
</style>